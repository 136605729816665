import React from "react";
import { Link, NavLink} from "react-router";
import "../../assets/style.scss";
import Login from "../../components/LoginModal/Login";
import { Prompt, Navigate } from "react-router";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import facebook from "../../assets/socilicons/fbnew.svg";
import twitter from "../../assets/socilicons/twtnew.svg";
import youtube from "../../assets/socilicons/ytubenew.svg";
import instagram from "../../assets/socilicons/instanew.svg";
import Crclogo from "../../assets/images/logo/crc_logo.png";
import SiteLogo from "../../assets/images/logo1.png";

const logo =  "https://ncrprodassets.s3.us-west-2.amazonaws.com/src_images/logo/logo.png";
const cross =  "https://d7le4ielk3ho1.cloudfront.net/src_images/cross.svg";
const userlogo =  "https://d7le4ielk3ho1.cloudfront.net/src_images/user.svg";
// const Crclogo =  "https://d7le4ielk3ho1.cloudfront.net/src_images/logo/crc_logo.png";

class navbar extends React.Component {
  constructor(props) {
    var today = new Date(),
      time = today.toISOString();
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.collapsemenu = this.collapsemenu.bind(this);
    this.closeCollapsemenu = this.closeCollapsemenu.bind(this);
    this.state = {
      loginmodale: false,
      show: "",
      activelink: false,
      confirming: true,
      currentTime: time,
      tournamenttime: localStorage.getItem("SatrtTime"),
      localtoken: localStorage.getItem("login"),
      redirect: false
    };
  }
  setAddClassb = () => {
    this.setState({
      addClass: false,
      show: false,
    });
  };
  handleShow() {
    console.log(this.state);
    this.setState({ show: true });
    this.setState({ addClass: false });
  }

  handleClose() {
    this.setState({ show: false });
    this.setState({ loginmodale: false });
  }
  handleMenu = () => {
    if (this.state.activeMenu) {
      document.getElementById("main-container").classList.remove("active");
      this.setState({ activeMenu: false });
    } else {
      document.getElementById("main-container").classList.add("active");
      this.setState({ activeMenu: true });
    }
  };

  componentDidMount() {
    console.log('tournamenttime',this.state.tournamenttime)
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
    });
  }

  collapsemenu() {
    const currentState = this.state.active;
    this.setState({
      active: !currentState,
    });
  }
  closeCollapsemenu() {
    const currentState = this.state.active;
    this.setState({
      active: !currentState,
    });
  }

  openScore = () => {
    console.log(this.props);
    //this.props.history.push('mens-score')
  };

  logout = () => {
    let SatrtTime = localStorage.SatrtTime
    localStorage.clear();
    localStorage.setItem('SatrtTime',SatrtTime)
    window.location.href= '/';
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Navigate to='/' />;
    }
    return (
      <>
        <div className="row">
          <div className="col-md-12 pad-right">
            <Navbar
              className={this.state.isTop ? "down" : "up"}
              bg="bright"
              variant="dark"
              expand="lg"
              sticky="top"
            >
              {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
              <Navbar.Brand className="navcolpsbtn">
                <div className="navmobilebuton">
                  <button
                    onClick={this.collapsemenu}
                    type="button"
                    className="navbar-toggler"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </div>
              </Navbar.Brand>
              {this.state.tournamenttime == "true" ? (
                <Navbar.Brand href="/">
                  <div className="navlogo crclog">
                    <img src={Crclogo} alt="deafult" />
                  </div>
                </Navbar.Brand>
              ) : (
                <Navbar.Brand href="/">
                  <div className="navlogo">
                    <img src={Crclogo} alt="deafult" />
                  </div>
                </Navbar.Brand>
              )}

              <Navbar.Brand className="usericon" style={{ padding: "0" }}>
                <div className="navuser">
                  {localStorage.getItem("login") ? (
                    <div>
                      <Dropdown className="colapsedropdownmenu">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <img src={userlogo} alt="deafult" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {localStorage.getItem("tp") !== "1" ? (
                            <>
                              <Dropdown.Item>
                                <Link to="/profile"> My Profile</Link>
                              </Dropdown.Item>
                              <div className="midline"></div>
                            </>
                          ) : (
                            ""
                          )}
                          {localStorage.getItem("tp") === "1" ? (
                            <Dropdown.Item>
                              <Link to="/manage-mens-bracket">
                                {" "}
                                Tournaments
                              </Link>
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item>
                              <Link to="/create-brackets"> My Bracket</Link>
                            </Dropdown.Item>
                          )}
                          <div className="midline"></div>
                          <Link to="#" onClick={this.logout}>
                            Logout
                          </Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : (
                    <Link to="#" onClick={this.handleShow}>
                      <img src={userlogo} alt="deafult" />
                    </Link>
                  )}
                </div>
              </Navbar.Brand>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav
                  id={localStorage.getItem("login") ? "afterloginnav" : ""}
                  className="mr-auto"
                >
                  <Link
                    style={{ marginLeft: "30px" }}
                    to="/mens-score"
                    className="btn btn-livescore 123"
                  >
                    Scoreboard
                  </Link>
                  {localStorage.getItem("login") ? (
                    <Form inline className="navdropbtn">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <span>
                            <img src={userlogo} alt="deafult" />
                          </span>
                          <span>MY ACCOUNT</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {localStorage.getItem("tp") !== "1" ? (
                            <>
                              <Dropdown.Item>
                                <Link to="/profile" className="nav-link"> My Profile</Link>
                              </Dropdown.Item>
                              <div className="midline"></div>
                            </>
                          ) : (
                            ""
                          )}

                          <Link to="#" className="nav-link" onClick={this.logout}>
                            Logout
                          </Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form>
                  ) : (<>
             <NavLink to="/sign-up" onClick={this.handleClose} className="nav-link disable-link"  activeClassName="active">
                        <span>SIGNUP</span>
                      </NavLink>
                   
                      <span>/</span>
                     
                      <NavLink to="/" onClick={this.handleShow} className="nav-link disable-link" activeClassName="active">
                        <img src={userlogo} alt="deafult" />
                        <span> LOGIN</span>
                      </NavLink>
                    </>
                  )}
                  {/* <div className="navbarmideline">&nbsp;</div> */}
                  {localStorage.getItem("login") ? (
                    localStorage.getItem("tp") === "1" ? (
                      <NavLink
                        className="nav-link"
                        to="/manage-mens-bracket"
                        activeClassName="active"
                        exact
                      >
                        <span>TOURNAMENTS</span>
                      </NavLink>
                    ) : (
                      <NavLink
                        className="nav-link"
                        to="/create-brackets"
                        activeClassName="active"
                        exact
                      >
                        <span> MY BRACKET</span>
                      </NavLink>
                    )
                  ) : null}
                  {/* <NavLink
                    activeClassName="active"
                    className="nav-link"
                    to="/match-times"
                    exact
                  >MATCH TIMES</NavLink> */}
                  {
                    localStorage.getItem('SatrtTime') === 'true' ?
                      // <NavLink
                      //   className="nav-link"
                      //   to="/where-to-watch"
                      //   activeClassName="active"
                      //   exact
                      // >
                      //   <span>WHERE TO WATCH</span>
                      // </NavLink>
                      <></>
                    :
                    <>
                    {/* <NavLink
                      className="nav-link disable-link"
                      to="/invite-friends"
                      activeClassName="active"
                      exact
                    >
                      <span>INVITE FRIENDS</span>
                    </NavLink> */}
                    <NavLink
                      className="nav-link disable-link"
                      to="/how-to-play"
                      activeClassName="active"
                      exact
                    >
                      <span> HOW TO PLAY</span>
                    </NavLink>
                    </>
                  }

                  <NavLink
                      className="nav-link disable-link"
                      to="/public-groups"
                      activeClassName="active"
                      exact
                    >
                      <span>PUBLIC GROUPS</span>
                    </NavLink>

                  {localStorage.getItem("login") ? (
                    <NavLink
                      className="nav-link"
                      to="/home"
                      activeClassName="active"
                      exact
                    >
                      <span>HOME</span>
                    </NavLink>
                  ) : (
                    <NavLink
                      className="nav-link"
                      to="/"
                      activeClassName="active"
                      exact
                    >
                      <span>HOME</span>
                    </NavLink>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <br />

            <div
              onClick={this.closeCollapsemenu}
              className={this.state.active ? "opensidenav" : "closesidenav"}
            >
              <div className="sidenavbar">
                <div className="closebutton">
                  <button
                    onClick={this.closeCollapsemenu}
                    type="btn"
                    className="btn btn-clodebtn"
                  >
                    <img src={cross} alt="x" />
                  </button>
                </div>

                <Nav className="sidenavlink mr-auto">
                  <Link to="/mens-score" className="btn btn-livescore 123">
                    Scoreboard
                  </Link>
                  <NavLink
                    activeClassName="active"
                    className="nav-link"
                    to="/"
                    exact
                  >
                    <span>HOME</span>
                  </NavLink>
                  <NavLink
                      className="nav-link disable-link"
                      to="/public-groups"
                      activeClassName="active"
                      exact
                    >
                      <span>PUBLIC GROUPS</span>
                  </NavLink>
                  {
                    localStorage.getItem('SatrtTime') === 'true' ?
                      <NavLink
                        activeClassName="active"
                        className="nav-link"
                        to="/invite-friends"
                        exact
                      >
                        <span>INVITE FRIENDS</span>
                      </NavLink>
                    :
                    <NavLink
                      className="nav-link disable-link"
                      to="/how-to-play"
                      activeClassName="active"
                      exact
                    >
                      <span> HOW TO PLAY</span>
                    </NavLink>
                  }
                  
                  {localStorage.getItem("login") ? (
                    localStorage.getItem("tp") === "1" ? (
                      <NavLink
                        className="nav-link"
                        to="/manage-mens-bracket"
                        activeClassName="active"
                        exact
                      >
                        <span>TOURNAMENTS</span>
                      </NavLink>
                    ) : (
                      <NavLink
                        className="nav-link"
                        to="/create-brackets"
                        activeClassName="active"
                        exact
                      >
                        <span> MY BRACKET</span>
                      </NavLink>
                    )
                  ) : null}
                  {/* <Link className="nav-link" to="/contact-us">
                    RULES
                  </Link> */}
                  {/* <NavLink
                    activeClassName="active"
                    className="nav-link"
                    to="/match-times"
                    exact
                  >MATCH TIMES</NavLink> */}
                  {/* {
                    localStorage.getItem('SatrtTime') === 'false' ?
                      <NavLink
                        activeClassName="active"
                        className="nav-link"
                        to="/how-to-play"
                        exact
                      >
                        <span> HOW TO PLAY</span>
                      </NavLink>
                    :
                    <NavLink
                        className="nav-link"
                        to="/where-to-watch"
                        activeClassName="active"
                        exact
                      >
                        <span>WHERE TO WATCH</span>
                      </NavLink>
                  } */}
                </Nav>

                <div className="midline"></div>

                <Nav className="sidenavlink">
                  <Link className="nav-link" to="/about-us">
                    <span>About Us</span>
                  </Link>
                  <NavLink className="nav-link" target="_blank" href="https://www.maymadness7s.com/contact-us">
                    <span> Contact Us</span>
                  </NavLink>
                  <NavLink className="nav-link" target="_blank" href="https://www.maymadness7s.com/privacy">
                    <span> Privacy Policy</span>
                  </NavLink>
                  <Link className="nav-link" to="/terms-and-conditions">
                    <span>Terms and Conditions</span>
                  </Link>
                </Nav>
                <div style={{ backgroundColor: "white" }}>
                  <div className="secondmidline"></div>
                </div>

                <div className="social">
                  <p>Follow us on</p>
                  <Nav className="socialmedia">
                    <NavLink
                      target="_blank"
                      href=" https://www.facebook.com/nationalcollegiaterugby/"
                    >
                      <img src={facebook} alt="deafult" />
                    </NavLink>
                    <NavLink
                      target="_blank"
                      href="https://twitter.com/ncrrugby?fbclid=IwAR3tHM239yQskkNNY_4v9Jlz7NvJuRTLul7jLZnuurKp5vLEfj5NVakA2d8"
                    >
                      <img src={twitter} alt="deafult" />
                    </NavLink>
                    <NavLink
                      target="_blank"
                      href="https://www.instagram.com/nationalcollegiaterugby/"
                    >
                      <img src={instagram} alt="deafult" />
                    </NavLink>
                    <NavLink
                      target="_blank"
                      href="https://www.youtube.com/c/NationalCollegiateRugby/featured"
                    >
                      <img src={youtube} alt="deafult" />
                    </NavLink>
                  </Nav>
                </div>
              </div>
            </div>
          </div>

          <Login
            show={this.state.show}
            onClick={() => this.setState({ show: false })}
            onClickB={this.setAddClassb}
          />
        </div>
      </>
    );
  }
}

export default navbar;
