import React, { useState, useEffect } from 'react';
import Aux from "../hoc/_Aux";
import { withRouter, Link } from "react-router";
import ReactPaginate from 'react-paginate';
import { Form, Button } from "react-bootstrap";
import LeaderIcon from "../assets/images/ic-leaderboard.svg";
import MemberIcon from "../assets/images/ic-member.svg";
import DeleteIcon from "../assets/images/ic-delete.svg";
import PublicIcon from "../assets/images/public-icon.jpg";
import PrivateIcon from "../assets/images/private.svg";
import MyGroupLogo from "../assets/images/logo3.png";
import SortIcon from "../assets/images/sort.svg";
import LeaderBoardTable from './LeaderBoardTable';
import MemberListTable from './MemberListTable';
import { GetData } from "../utils/apiRequestHandler";
// import LeaderBoardTable from "./LeaderBoardTable";
// import MemberListTable from "./MemberListTable";
import DeleteGroup from "./groupModal/DeleteGroup";
import { List } from 'antd';

function GroupsTable() {
    const [visibleTableComp, setVisibleTableComp] = useState(true);
    const [visibleLedBoardComp, setVisibleLedBoardComp] = useState({});
    const [visibleMemberListComp, setVisibleMemberListComp] = useState({});
    const [filterTop, setFilterTop] = useState(true);
    const [backToGroup, setBackToGroup] = useState(false);
    const [headText, setHeadText] = useState("GROUPS");
    const [loader, setloader] = useState(false);
    const [searchVal, setsearchVal] = useState('')
    const [memberList, setmemberList] = useState([]);
    const [limit, setlimit] = useState(10);
    const [page, setpage] = useState(1);
    const [sortBy, setsortBy] = useState('');
    const [sortOrder, setsortOrder] = useState('ASC');
    const [groupList, setgroupList] = useState([]);
    const [count, setCount] = useState(0);
    const [s3url, seturl] = useState('');
    const [type, setType] = useState('all');
    const [filterBy, setFilterBy] = useState('all');
    const [deleteModal, setdeleteModal] = useState({
        show: false,
        hide: null,
        deleteId: null,
        type: 'delete'
    })

    const closeDeleteModal = (status) => {
        setdeleteModal({
            show: false,
            hide: closeDeleteModal,
            deleteId: null,
            type: 'delete'
        })
        backClick();
        getGroups();
    }
    const openDeleteModal = (id) => {
        let rv = Math.random();
        setdeleteModal({
            show: true,
            hide: closeDeleteModal,
            deleteId: id,
            type: 'delete',
            randomValue: rv

        })
    }

    const getmemberList = (id) => {
        setloader(true);
        GetData("groups/member?group_id=" + id).then((response) => {
            console.log("Response", response);
            if (response.status && response.data) {
                setloader(false);
                setmemberList(response.data);
            } else {
                setloader(false);
                setmemberList([]);
            }
        });
    }

    const changeSortBy = (val) => {
        if( val === sortBy ) {
            setsortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
        } else {
            setsortBy(val);
            setsortOrder('ASC');
        }
    }

    const searchValue = (e) => {
        let value = e.target.value;
        setsearchVal(value)
    }
    const clickLeader = (data) => {
        setVisibleTableComp(false);
        setVisibleLedBoardComp({...data});
        setFilterTop(false);
        setBackToGroup(true);
        setHeadText("LEADERBOARD");
    }
    const clickMember = (data) => {
        setVisibleTableComp(false);
        setVisibleMemberListComp({...data});
        setFilterTop(false);
        setBackToGroup(true);
        setHeadText("MEMBER LIST");
    }
    const backClick = () => {
        setVisibleTableComp(true);
        setVisibleLedBoardComp({});
        setVisibleMemberListComp({});
        setFilterTop(true);
        setBackToGroup(false);
        setHeadText("GROUPS");
    }
    const changeLimit = (event) => {
        let page = parseInt(event.selected) + 1;
        setpage(page);
    };
    
    const getGroups = () => {
        setloader(true);
        let url = `groups/getAllGroups?limit=${limit}&page=${page}&type=${type}&filterBy=${filterBy}`;
        if (sortBy) {
            url += `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }
        if (searchVal) {
            url += `&searhValue=${searchVal}`;
        }
        GetData(url).then((response) => {
            if (response.status && response.data && response.data.rows) {
                console.log(response.data);
                setgroupList(response.data.rows);
                setCount(response.data.count);
                seturl(response.data.s3url);
            } else {
                setgroupList([]);
                setCount(0);
            }
        });
    }
    useEffect(() => {
        getGroups();
    }, [page, sortOrder,type,filterBy])

    useEffect(() => {
        if( searchVal.length !== 1) {
            getGroups();
        }
    }, [searchVal])

    

    useEffect(() => {
        if (visibleMemberListComp.id) {
            getmemberList(visibleMemberListComp.id);
        }
    }, [visibleMemberListComp])

    return (
        <Aux>
            <div className="groups-head-outer">
                <div className="groups-head">
                    {headText}
                </div>
                {filterTop && <>
                    <div className="filter-search-box">
                        <div className="filter-search-inner">
                            <div className="groups-filter">
                                <Form.Group>
                                    <span>Type:</span>
                                    <select
                                        className="form-control"
                                        onChange={(e) => setFilterBy(e.target.value) }
                                        value={filterBy}
                                    >
                                        <option value="all">ALL</option>
                                        <option value="public">Public</option>
                                        <option value="private">Private</option>
                                    </select>
                                </Form.Group>
                            </div>
                            <div className="groups-filter">
                                <Form.Group>
                                    <span>Gender:</span>
                                    <select
                                        className="form-control"
                                        onChange={(e) => setType(e.target.value) }
                                        value={type}
                                    >
                                        <option value="all">ALL</option>
                                        <option value="male">Men's</option>
                                        <option value="female">Women's</option>
                                    </select>
                                </Form.Group>
                            </div>
                            <div className="search-top-groups">
                                <Form.Control type="text" onChange={(e) => { searchValue(e) }} className={searchVal ? "search-group focus" : "search-group"} placeholder="Search..." value={searchVal} />
                                <button type="button" className="btn">
                                    <svg width="15px" height="15px">
                                        <path d="M11.618 9.897l4.224 4.212c.092.09.1.23.02.312l-1.464 1.46c-.08.08-.222.072-.314-.02L9.868 11.66M6.486 10.9c-2.42 0-4.38-1.955-4.38-4.367 0-2.413 1.96-4.37 4.38-4.37s4.38 1.957 4.38 4.37c0 2.412-1.96 4.368-4.38 4.368m0-10.834C2.904.066 0 2.96 0 6.533 0 10.105 2.904 13 6.486 13s6.487-2.895 6.487-6.467c0-3.572-2.905-6.467-6.487-6.467 "></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </>}
                {/* {backToGroup && <>
                    <span className="back-top-groups" onClick={() => backClick()}>Back to Groups</span>
                </>
                } */}
            </div>
            {visibleTableComp && <>
                <div className="groups-table-outer-wrap">
                    <div className="table-height">
                        <table className="table-style-groups">
                            <thead>
                                <tr>
                                    {/* <th><span>S. No.</span></th> */}
                                    <th>
                                        <span>Admin Name</span>
                                        <img onClick={() => {changeSortBy('userName')}} src={SortIcon} alt="Sort" className="sort-users" />
                                    </th>
                                    <th>
                                        <span>Group Name</span>
                                        <img onClick={() => {changeSortBy('group_name')}} src={SortIcon} alt="Sort" className="sort-users" />
                                    </th>
                                    <th>
                                        <span>Users</span>
                                        <img onClick={() => {changeSortBy('member_count')}} src={SortIcon} alt="Sort" className="sort-users" />
                                    </th>
                                    <th>
                                        <span>Type</span>
                                        <img onClick={() => {changeSortBy('is_public')}} src={SortIcon} alt="Sort" className="sort-users" />
                                    </th>
                                    <th>
                                        <span>Gender</span>
                                        <img onClick={() => {changeSortBy('tournament_type')}} src={SortIcon} alt="Sort" className="sort-users" />
                                    </th>
                                    <th><span>Action</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {groupList.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            {/* <td>{data.id}</td> */}
                                            <td>{data.userName}</td>
                                            <td>{data.group_name}</td>
                                            <td>{data.member_count}</td>
                                            <td>{data.is_public ? 'Public' : 'Private'}</td>
                                            <td>{data.tournament_type && data.tournament_type === "male" ? "Men's" : "Women's"}</td>
                                            <td>
                                                <div className="action-btns">
                                                    <span onClick={() => clickLeader(data)}><img src={LeaderIcon} alt="" /></span>
                                                    <span onClick={() => clickMember(data)}><img src={MemberIcon} alt="" /></span>
                                                    <span onClick={() => openDeleteModal(data.id)}><img src={DeleteIcon} alt="" /></span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                    {/* pagination */}
                    <div className="pagination-group">
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="next"
                            onPageChange={changeLimit}
                            pageRangeDisplayed={10}
                            pageCount={count ? count % 10 === 0 ? parseInt(count / 10) : parseInt(count / 10) + 1 : 0}
                            previousLabel="prev"
                            renderOnZeroPageCount={null}
                        />
                    </div>

                </div>
            </>
            }
            {/* Leader board section */}
            {visibleLedBoardComp.id && <>
                {/* white box start */}
                <div className="white-box groups-details">
                    <div className="white-box-inner">
                        <div className="my-group-left">
                            <div className="group-left-inner">
                                <div className="mygroup-logo">
                                    <img src={visibleLedBoardComp.group_image ? s3url + visibleLedBoardComp.group_image : MyGroupLogo} alt="Logo" />
                                </div>
                                <div className="bracket-box-name">
                                    <span className="name-inner">{visibleLedBoardComp.group_name}</span>
                                    <div className="public-logo-wrap">
                                        <span className="mens-circle">{visibleLedBoardComp.tournament_type === 'male' ? "men's" : "women's"}</span>
                                        <div className="public-icon">
                                            <img src={visibleLedBoardComp.is_public ? PublicIcon: PrivateIcon} alt="Icon" />
                                            {visibleLedBoardComp.is_public ? "Public" : "Private"} <span>({visibleLedBoardComp.member_count})</span>
                                        </div>
                                    </div>

                                    <div className="group-text">
                                        <div className="username-white">Username: <span>{visibleLedBoardComp.userName}</span></div>
                                        {visibleLedBoardComp.group_description ? visibleLedBoardComp.group_description : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-btns">
                            <span className="edit-dt-group">
                                <Link to="#" onClick={(e) => { e.preventDefault(); openDeleteModal(visibleLedBoardComp.id) }} className="deletebtn">Delete</Link>
                            </span>
                        </div>
                    </div>
                    <LeaderBoardTable
                        groupData={visibleLedBoardComp}
                        adminPage={true}
                    />
                </div>
                {/* end */}
            </>

            }
            {/* User list section */}
            {visibleMemberListComp.id && <>
                {/* white box start */}
                <div className="white-box groups-details">
                    <div className="white-box-inner">
                        <div className="my-group-left">
                            <div className="group-left-inner">
                                <div className="mygroup-logo">
                                    <img src={visibleMemberListComp.group_image ? s3url + visibleMemberListComp.group_image : MyGroupLogo} alt="Logo" />
                                </div>
                                <div className="bracket-box-name">
                                    <span className="name-inner">{visibleMemberListComp.group_name}</span>
                                    <div className="public-logo-wrap">
                                        <span className="mens-circle">{visibleMemberListComp.tournament_type === 'male' ? "men's" : "women's"}</span>
                                        <div className="public-icon">
                                            <img src={visibleMemberListComp.is_public ? PublicIcon: PrivateIcon} alt="Icon" />
                                            {visibleMemberListComp.is_public ? "Public" : "Private"} <span>({visibleMemberListComp.member_count})</span>
                                        </div>
                                    </div>

                                    <div className="group-text">
                                        <div className="username-white">Username: <span>{visibleMemberListComp.userName}</span></div>
                                        {visibleMemberListComp.group_description ? visibleMemberListComp.group_description : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-btns">
                            <span className="edit-dt-group">
                                <Link to="#" onClick={(e) => { e.preventDefault(); openDeleteModal(visibleMemberListComp.id) }} className="deletebtn">Delete</Link>
                            </span>
                        </div>
                    </div>
                    <MemberListTable
                        memberList={memberList}
                        groupData={visibleMemberListComp}
                        getmemberList={getmemberList}
                    />
                </div>
                {/* end */}
            </>

            }
            <DeleteGroup
                {...deleteModal}
            />
        </Aux>
    )
}
export default GroupsTable;