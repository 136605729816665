import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "../../assets/style.scss";
import Aux from "../../hoc/_Aux";
import { Form, Button, Modal } from "react-bootstrap";
import { DeleteData } from "../../utils/apiRequestHandler";
import { globalVariable } from "../../utils/global";
import { consts } from "../../utils/validation";
import UploadLogo from "../../assets/images/logo-upload.jpg";

const Openeye =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye.png";
const Closeye =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye-close.png";
const loginclose =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/login-cross.svg";


export default function DeleteGroup(props){
  const [showSpinner, setShowSpinner] = useState(false);
  const [errorMsg, seterrorMsg] = useState('');
  const [successMsg, setsuccessMsg] = useState('');
  useEffect(() => {
    setsuccessMsg('');
    seterrorMsg('');
  },[props])


  const deleteGroup = () => {
    setShowSpinner(true);
    setsuccessMsg('');
    seterrorMsg('');
    let bid = props.deleteId;
    let formData = {group_id : bid};
    DeleteData(`groups`, formData).then((response) => {
      setShowSpinner(false);
      if (response.status) {
        setsuccessMsg(response.message);
        setTimeout(() => {
          props.hide && props.hide(response.status);
        },1000)
          
      } else {
        seterrorMsg(response.message);
      }
  });
  }

  const leaveGroup = () => {
    // return false;
    setShowSpinner(true);
    setsuccessMsg('');
    seterrorMsg('');
    let bid = props.deleteId;
    let formData = {group_id : bid};
    DeleteData(`groups/member`, formData).then((response) => {
      setShowSpinner(false);
      if (response.status) {
        setsuccessMsg(response.message);
        setTimeout(() => {
          props.hide && props.hide(response.status);
        },1000)
          
      } else {
        seterrorMsg(response.message);
      }
  });
  }


  return(
    <Aux>
        <Modal
          show={props.show}
          centered
          onHide={() => {props.hide && props.hide(false)}}
          show1={props.show2}
          animation={false}
          className="modal-style-groups"
        >
        <Modal.Header  className="header-style-modal">
            <Button onClick={props.hide} className="btn closebtn">
              <img src={loginclose} alt="deafult" />
            </Button>
            <Modal.Title>{props.type && props.type === 'leave' ? 'LEAVE' : 'DELETE'} GROUP</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-small">
            <div className="delete-grp-text">
                Are you sure you want to {props.type && props.type === 'leave' ? 'leave' : 'delete'} this group?
            </div>  
            <div className="error-msg">{errorMsg}</div>
            <div className="success-msg">{successMsg}</div>
            <div className="footer-btn footer-btn-delete-modal">
            {props.type && props.type === 'delete' ? 
              <Button
                onClick={()=> {deleteGroup()}}
                variant="primary"
                type="submit"
              >
                {showSpinner && (
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                )}
                DELETE
              </Button> : <Button
                onClick={()=> {leaveGroup()}}
                variant="primary"
                type="submit"
              >
                {showSpinner && (
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                )}
                LEAVE
              </Button>
            }
            {props.show2 && props.type && props.type === 'delete' ? <Button className="cancel-btn transfer-btn"
              onClick={props.show2}
            >
              TRANSFER OWNERSHIP
            </Button> : ''}

            {!props.show2 && props.type && props.type === 'delete' ? <Button className="cancel-btn transfer-btn"
              onClick={() => {props.hide && props.hide(false)}}
            >
              Cancel
            </Button> : ''}
              
            </div>
          </Modal.Body>
         
        </Modal>
    </Aux>
  )
}