import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import "../../assets/style.scss";
import Aux from "../../hoc/_Aux";
import { Form, Button, Modal } from "react-bootstrap";
import UploadLogo from "../../assets/images/logo-upload.jpg";
import InputValidation from "../../utils/validationNew";
import Multiselect from 'multiselect-react-dropdown';
import { GetData, PostData, PutData } from "../../utils/apiRequestHandler";
import { consts } from "../../utils/global";

const loginclose = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/login-cross.svg";


export default function CreateGroupModal(props) {
    const [picture, setPicture] = useState(null);
    const [imgData, setImgData] = useState(null);
    const [errFileSize, setErrFileSize] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);
    const [selectedBracket, setselectedBracket] = useState({
        arrList: [],
        objList: []
    })
    const { emailRegex } = consts; // --  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
    const [tags, setTags] = useState([]);
    const [tag, setTag] = useState('');
    const [errorMsg, seterrorMsg] = useState('');
    const [successMsg, setsuccessMsg] = useState('');
    const [bracketData, setbracketData] = useState([]);
    const groupNameErrorMsg = ['Group Name is required', 'Group Name is Invalid'];
    const selectBracketErrorMsg = ['Select your bracket'];
    const selectTypeErrorMsg = ['Select your type'];
    const groupDescriptionErrorMsg = ['Enter Valid description'];
    const groupMessageErrorMsg = ['Enter Valid message'];


    const [errors, setErrors] = useState({
        eGroupName: false,
        eSelectBracket: false,
        eSelectType: false,
        eGroupDescription: false,
        eGroupMessage: false,
        einviteFriends: false
    });

    const [userData, setUserData] = useState({
        groupId: {
            value: '',
            validation: []
        },
        groupName: {
            value: '',
            validation: ['blank', 'alphanumeric'],
            errorMsg: groupNameErrorMsg,
            errorKey: 'eGroupName',
        },
        selectBracket: {
            value: '',
            validation: [],
            errorMsg: selectBracketErrorMsg,
            errorKey: 'eSelectBracket',
        },
        selectType: {
            value: '',
            validation: ['blank'],
            errorMsg: selectTypeErrorMsg,
            errorKey: 'eSelectType',
        },
        groupDescription: {
            value: '',
            validation: [],
            errorMsg: groupDescriptionErrorMsg,
            errorKey: 'eGroupDescription',
        },
        groupMessage: {
            value: '',
            validation: [],
            errorMsg: groupMessageErrorMsg,
            errorKey: 'eGroupMessage',
        },
        is_public: {
            value: 0,
            validation: [],
            errorMsg: '',
            errorKey: '',
        }

    });

    useEffect(() => {
        let tempUserData = [],
            arrList = [],
            objList = [],
            tempData = [];
        tempData = props.bracketData.filter((d) => {
            if (d.type === userData.selectType.value) {
                // arrList.push(d.id)
                // objList.push(d);
                return true;
            }
        });
        // // tempUserData.selectBracket.value = '';
        // if (tempData.length) {
        //     tempData.filter((d) => {
        //         if (userData.selectBracket.value.indexOf(d.id) > '-1') {
        //             tempUserData.push(d.id);
        //             return true;
        //         }
        //     });
        // }
        // setUserData({
        //     ...tempUserData
        // })
        setselectedBracket({
            arrList: [],
            objList: []
        });
        setbracketData([...tempData]);

    }, [userData.selectType.value])


    useEffect(() => {
        seterrorMsg('');
        setsuccessMsg('');
        setTags([]);
        // console.log( 'ed', props.editGroupData);
        if (props.editGroupData) {
            let ed = props.editGroupData;
            if (ed.user_bracket_id && ed.tournament_type) {
                let arrList = [], objData = [],
                    userbdData = String(ed.user_bracket_id).split(',');
                props.bracketData.map((bd) => {
                    if (userbdData.indexOf(String(bd.id)) > '-1' && ed.tournament_type === bd.type) {
                        arrList.push(bd.id);
                        objData.push(bd)
                    }
                })

                setselectedBracket({
                    arrList: [...arrList],
                    objList: [...objData]
                });

            }
            setUserData({
                groupId: {
                    value: ed.id,
                    validation: []
                },
                groupName: {
                    value: ed.group_name,
                    validation: ['blank', 'alphanumeric'],
                    errorMsg: groupNameErrorMsg,
                    errorKey: 'eGroupName',
                },
                selectBracket: {
                    value: ed.user_bracket_id ? String(ed.user_bracket_id).split(',') : [],
                    validation: [],
                    errorMsg: selectBracketErrorMsg,
                    errorKey: 'eSelectBracket',
                },
                selectType: {
                    value: ed.tournament_type,
                    validation: ['blank'],
                    errorMsg: selectTypeErrorMsg,
                    errorKey: 'eSelectType',
                },
                groupDescription: {
                    value: ed.group_description,
                    validation: [],
                    errorMsg: groupDescriptionErrorMsg,
                    errorKey: 'eGroupDescription',
                },
                groupMessage: {
                    value: ed.message ? ed.message : '',
                    validation: [],
                    errorMsg: groupMessageErrorMsg,
                    errorKey: 'eGroupMessage',
                },
                is_public: {
                    value: ed.is_public,
                    validation: [],
                    errorMsg: '',
                    errorKey: '',
                }

            })

            if (ed.group_image) {
                setImgData(`${props.s3URL}${ed.group_image}`);
            }
        } else {
            setUserData({
                groupId: {
                    value: '',
                    validation: []
                },
                groupName: {
                    value: '',
                    validation: ['blank', 'alphanumeric'],
                    errorMsg: groupNameErrorMsg,
                    errorKey: 'eGroupName',
                },
                selectBracket: {
                    value: [],
                    validation: [],
                    errorMsg: selectBracketErrorMsg,
                    errorKey: 'eSelectBracket',
                },
                selectType: {
                    value: '',
                    validation: ['blank'],
                    errorMsg: selectTypeErrorMsg,
                    errorKey: 'eSelectType',
                },
                groupDescription: {
                    value: '',
                    validation: [],
                    errorMsg: groupDescriptionErrorMsg,
                    errorKey: 'eGroupDescription',
                },
                groupMessage: {
                    value: '',
                    validation: [],
                    errorMsg: groupMessageErrorMsg,
                    errorKey: 'eGroupMessage',
                },
                is_public: {
                    value: 0,
                    validation: [],
                    errorMsg: '',
                    errorKey: '',
                }

            })
            setselectedBracket({
                arrList: [],
                objList: []
            });
        }
    }, [props.randomValue])

    const onChangePicture = (e) => {
        if (e.target.files[0]) {
            console.log("picture: ", e.target.files);
            setPicture(e.target.files[0]);
            let reader = new FileReader();
            let file_size = e.target.files[0].size;
            if (file_size > 10485760) {
                setErrFileSize('File size should not be more than 10 MB');
            } else {
                setErrFileSize('');
                reader.onloadend = () => {
                    setImgData(reader.result)
                };
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onBracketSelect = (sl, si) => {
        let tempList = selectedBracket.arrList;
        setselectedBracket({
            arrList: [...tempList, si.id],
            objList: sl
        });

    }

    const onBracketRemove = (sl, ri) => {
        let tempList = selectedBracket.arrList;
        let index = tempList.indexOf(ri.id);
        if (index > '-1') {
            tempList.splice(index, 1)
        }
        setselectedBracket({
            arrList: [...tempList],
            objList: sl
        });
    }

    const handleCreateGroup = () => {
        setShowSpinner(true);
        seterrorMsg('');
        setsuccessMsg('');
        let tempInvite = [];
        const validationResult = InputValidation(userData);
        // console.log( 'validationResult-->',validationResult )
        if (Object.keys(validationResult).length > 0) {
            setErrors({ ...errors, ...validationResult });
            setShowSpinner(false);
        } else {
            tempInvite = tags.filter((d) => {
                if (!emailRegex.test(d)) {
                    return true;
                }
            })
            if (tempInvite.length) {
                setErrors({ ...errors, einviteFriends: 'Please enter valid emails' });
                setShowSpinner(false);
                return false;
            }
            let userBracketId = [];
            // if( selectedBracket.objList.length ) {
            //     selectedBracket.map((ubId) => {
            //         userBracketId.push(ubId.id);
            //     })
            // }

            const formData = new FormData();
            formData.append("group_image", picture);
            formData.append("group_name", userData.groupName.value.trim());
            formData.append("group_description", userData.groupDescription.value.trim());
            formData.append("message", userData.groupMessage.value.trim());
            formData.append("is_public", userData.is_public.value);
            formData.append("group_invite", tags.join(','));
            formData.append("user_bracket_id", selectedBracket.arrList.join(','));

            if (userData.groupId.value) {
                formData.append("group_id", userData.groupId.value);
                PutData("groups", formData).then((response) => {
                    if (response.status === true) {
                        setShowSpinner(false);
                        seterrorMsg('');
                        setsuccessMsg('Group Updated Successfuly');
                        setTimeout(() => {
                            props.hide && props.hide(response.status, userData.selectType.value.trim());
                        }, 1500)
                    } else {
                        console.log("Error while fetching data");
                        setShowSpinner(false);
                        setsuccessMsg('');
                        seterrorMsg(response.message);

                    }
                });
            } else {
                formData.append("tournament_type", userData.selectType.value.trim());
                PostData("groups", formData).then((response) => {
                    setShowSpinner(true);
                    if (response.status === true) {
                        setShowSpinner(false);
                        seterrorMsg('');
                        setsuccessMsg('Group Created Successfuly');
                        setTimeout(() => {
                            props.hide && props.hide(response.status, userData.selectType.value.trim());
                        }, 1500)
                    } else {
                        setShowSpinner(false);
                        console.log("Error while fetching data");
                        setsuccessMsg('');
                        seterrorMsg(response.message);

                    }
                });
            }
        }
    }

    const handleChange = (tg) => {
        setErrors({ ...errors, einviteFriends: false });
        let mtg = tg;
        if (mtg && mtg.length) {
            mtg[mtg.length - 1] = mtg[mtg.length - 1].trim()
        }
        setTags(mtg);
    };

    const handleChangeInput = (tag) => {
        setErrors({ ...errors, einviteFriends: false });
        if (tag.indexOf(',') > -1) {
            let temptg = tags, emparr = [],
                temptag = tag.split(',');
            emparr = temptag.filter((d) => {
                if (d && d != '') {
                    return true;
                }
            })
            if (emparr.length) {
                setTags([...temptg, ...emparr]);
                setTag('');
            }
        } else {
            setTag(tag);
        }
    };

    const showPublic = (e) => {
        if (e.target.checked) {
            setUserData({
                ...userData,
                ...{
                    is_public: {
                        value: 1,
                        validation: [],
                        errorMsg: '',
                        errorKey: '',
                    },
                }
            })
        } else {
            setUserData({
                ...userData,
                ...{
                    is_public: {
                        value: 0,
                        validation: [],
                        errorMsg: '',
                        errorKey: '',
                    },
                }
            })
        }
    }


    return (
      <Aux>
        <Modal
          show={props.show}
          centered
          onHide={props.hide}
          animation={false}
          className="modal-style-groups"
        >
          <Modal.Header  className="header-style-modal">
            <Button onClick={props.hide} className="btn closebtn">
              <img src={loginclose} alt="deafult" />
            </Button>
            <Modal.Title>
              {userData.groupId.value ? "UPDATE" : "CREATE"} YOUR GROUP
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <Form.Group>
              <Form.Check
                type="checkbox"
                id="check-field"
                value="1"
                checked={userData.is_public.value ? true : false}
                onChange={showPublic}
                label="Make group publicly visible"
              />
            </Form.Group>
            <div className="upload-logo">
              <div className="logo-box">
                <img
                  src={imgData === null ? UploadLogo : imgData}
                  alt="image"
                  role="button"
                  onClick={() => document.getElementById("getFile").click()}
                />
              </div>
              <span
                className="upload-logo-text"
                onClick={() => document.getElementById("getFile").click()}
              >
                Upload Logo
              </span>

              <input
                onChange={(e) => onChangePicture(e)}
                type="file"
                id="getFile"
                style={{ display: "none" }}
                accept="image/x-png,image/gif,image/jpeg"
              />
              <p className="err-paragraph error-upload">{errFileSize}</p>
            </div>
            <Form.Group>
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                onClick={() =>
                  setErrors({ ...errors, ...{ eGroupName: false } })
                }
                onFocus={() =>
                  setErrors({ ...errors, ...{ eGroupName: false } })
                }
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    ...{
                      groupName: {
                        value: e.currentTarget.value,
                        validation: ["blank", "alphanumeric"],
                        errorMsg: groupNameErrorMsg,
                        errorKey: "eGroupName",
                      },
                    },
                  })
                }
                value={userData.groupName.value}
                type="text"
                placeholder="Enter group name"
              />
              <div className="err-paragraph">{errors.eGroupName}</div>
            </Form.Group>

            <Form.Group>
              <label className="form-label">Select Your Tournament type </label>
              <select
                disabled={userData.groupId.value ? true : false}
                className="form-control"
                value={userData.selectType.value}
                onClick={() =>
                  setErrors({ ...errors, ...{ eSelectType: false } })
                }
                onFocus={() =>
                  setErrors({ ...errors, ...{ eSelectType: false } })
                }
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    ...{
                      selectType: {
                        value: e.currentTarget.value,
                        validation: ["blank"],
                        errorMsg: selectTypeErrorMsg,
                        errorKey: "eSelectType",
                      },
                    },
                  })
                }
              >
                <option value="0">Select Tournament Type</option>
                <option value="male">Men's</option>
                <option value="female">Women's</option>
              </select>
              <div className="err-paragraph">{errors.eSelectType}</div>
            </Form.Group>

            <Form.Group>
              <label className="form-label">
                Select Your Bracket (optional)
              </label>
              {/* <select
                    className="form-control"
                    value={userData.selectBracket.value}
                    onClick={()=> setErrors({...errors, ...{eSelectBracket: false}})}
                    onFocus={()=> setErrors({...errors, ...{eSelectBracket: false}})}
                    onChange={(e)=>setUserData({
                        ...userData,
                        ...{
                            selectBracket: {
                                value: e.currentTarget.value,
                                validation: [],
                                errorMsg: selectBracketErrorMsg,
                                errorKey: 'eSelectBracket',
                                },
                            }
                        })
                    }
                >
                    <option value="">Select</option>
                    {
                        bracketData.map((d) =>
                           <option value={d.id}>{d.name}</option>
                        )
                    }
                </select> */}
              <Multiselect
                options={bracketData} // Options to display in the dropdown
                selectedValues={selectedBracket.objList} // Preselected value to persist in dropdown
                onSelect={onBracketSelect} // Function will trigger on select event
                onRemove={onBracketRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                selectionLimit={5}
                placeholder="Select Bracket"
              />
              <div className="err-paragraph">{errors.eSelectBracket}</div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Group Description</Form.Label>
              <Form.Control
                className="textarea-modal"
                as="textarea"
                maxLength={500}
                rows={6}
                placeholder="Enter group description"
                value={userData.groupDescription.value}
                onClick={() =>
                  setErrors({ ...errors, ...{ eGroupDescription: false } })
                }
                onFocus={() =>
                  setErrors({ ...errors, ...{ eGroupDescription: false } })
                }
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    ...{
                      groupDescription: {
                        value: e.currentTarget.value,
                        validation: [],
                        errorMsg: groupDescriptionErrorMsg,
                        errorKey: "eGroupDescription",
                      },
                    },
                  })
                }
              />
              <div className="err-paragraph">{errors.eGroupDescription}</div>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Invite Friends (e-mails should be comma seperated)
              </Form.Label>
              <TagsInput
                id="addtags"
                addOnBlur={() => document.getElementById("addtags").click()}
                value={tags}
                onChange={handleChange}
                inputValue={tag}
                onChangeInput={handleChangeInput}
                inputProps={{
                  placeholder: "eg. xyz@domainname.com, zzz@domain.com",
                }}
              />
              <div className="err-paragraph">{errors.einviteFriends}</div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Message</Form.Label>
              <Form.Control
                className="textarea-modal"
                as="textarea"
                maxLength={500}
                rows={6}
                placeholder="Enter message"
                value={userData.groupMessage.value}
                onClick={() =>
                  setErrors({ ...errors, ...{ eGroupMessage: false } })
                }
                onFocus={() =>
                  setErrors({ ...errors, ...{ eGroupMessage: false } })
                }
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    ...{
                      groupMessage: {
                        value: e.currentTarget.value,
                        validation: [],
                        errorMsg: groupMessageErrorMsg,
                        errorKey: "eGroupMessage",
                      },
                    },
                  })
                }
              />
              <div className="err-paragraph">{errors.eGroupMessage}</div>
            </Form.Group>

          
            <div className="error-msg-left">{errorMsg}</div>
            <div className="success-msg-left">{successMsg}</div>
            <div className="footer-btn">
              <Button
                onClick={handleCreateGroup}
                variant="primary"
                type="submit"
              >
                {showSpinner && (
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                )}
                {userData.groupId.value ? "Update" : "Create"}
              </Button>
              <Button className="cancel-btn" onClick={props.hide}>
                CANCEL
              </Button>
            </div>
          </Modal.Body>

          
        </Modal>
      </Aux>
    );
}

