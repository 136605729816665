import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "../../assets/style.scss";
import Aux from "../../hoc/_Aux";
import { Form, Button, Modal } from "react-bootstrap";
import { GetData, PostData } from "../../utils/apiRequestHandler";

const loginclose =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/login-cross.svg";


export default function ConfirmationModal(props){
  
  const callBack = () => {
    props.modalAction && props.modalAction();
  }




  return(
    <Aux>
        <Modal
          show={props.show}
          centered
          onHide={props.hide}
          animation={false}
          className="modal-style-groups"
        >
        <Modal.Header  className="header-style-modal">
            <Button onClick={() => {props.hide(false)}} className="btn closebtn">
              <img src={loginclose} alt="deafult" />
            </Button>
            <Modal.Title>{props.modalTitle ? props.modalTitle : 'Confirmation'}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-small">
            <div className="delete-grp-text">
              {props.modalMessage ? props.modalMessage : 'Are you sure'}
            </div>  
            <div className="footer-btn footer-btn-delete-modal">
              <Button
                onClick={()=> {callBack()}}
                variant="primary"
                type="submit"
              >
                {props.yesButtonName ? props.yesButtonName : 'Ok'}
              </Button>
              <Button className="cancel-btn transfer-btn"
                onClick={() => {props.hide(true)}}
              >
                {props.noButtonName ? props.noButtonName : 'Cancel'}
              </Button>
            </div>
          </Modal.Body>
         
        </Modal>
    </Aux>
  )
}