import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "./includes/Navbar";
import { withRouter } from "../components/withRouter";
import { Row, Col, Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeadd from "../components/Placeadd";
import Placeaddsm from "../components/Placeaddsm";
import { GetData, PostData } from "../utils/apiRequestHandler";
import BracketBox from "../components/brackets/BracketBox";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import GroupsTable from "../components/GroupsTable";
const logo =  "https://ncrprodassets.s3.us-west-2.amazonaws.com/src_images/logo/logo.png";

class RugbyBracket extends React.Component {
  constructor(props) {
    
    super(props);
    this.state = {
      loader: false,
      modalIsOpen: false,
      type: "input",
      addClass: false,
      addClass1: false,
      addClass2: false,
      addClass3: false,
      tabNumber: 1,
      mensLeagueTopLeft: [],
      mensLeagueBottomLeft: [],
      mensLeagueTopRight: [],
      mensLeagueBottomRight: [],
      mensLeagueCenter: [],
      mensCenterText: "--",
      mensTopLeftText: "--",
      mensTopRightText: "--",
      mensBottomLeftText: "--",
      mensBottomRightText: "--",
      survivorsCenter: [],
      survivorsLeft: [],
      survivorsRight: [],
      challengeCenter: [],
      challengeLeft: [],
      challengeRight: [],
      championLeft: [],
      championRight: [],
      championCenter: [],
      showPremierScroll: true,
    };
    this._userBracketId = "";
    this.showHide = () => this.showHide.bind(this);
  }

  componentDidMount() {
    document.title = "NCR Rugby | Admin Men's Bracket";
    if (localStorage.tp !== "1") {
      this.props.navigate("/");
    } else {
      this.getBrackets();
    }
  }

  getBrackets = () => {
    this.setState({ loader: true });
    GetData("getGameLists?gender=male").then((response) => {
      //console.log('Response',response)
      this.setState({ loader: false });
      if (response.status === true) {
        this._userBracketId = response.data[0].user_bracket_id;
        const mensLeagueTopLeft = response.data[0].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const mensLeagueBottomLeft = response.data[0].brackets.find(
          (x) => x.bracket_position === "bottom_left"
        );
        const mensLeagueTopRight = response.data[0].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const mensLeagueBottomRight = response.data[0].brackets.find(
          (x) => x.bracket_position === "bottom_right"
        );
        const mensLeagueCenter = response.data[0].brackets.find(
          (x) => x.bracket_position === "center"
        );

        const survivorsLeft = response.data[1].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const survivorsRight = response.data[1].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const survivorsCenter = response.data[1].brackets[2];

        const challengeLeft = response.data[2].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const challengeRight = response.data[2].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const challengeCenter = response.data[2].brackets.find(
          (x) => x.bracket_position === "center"
        );

        const championLeft = response.data[3].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const championRight = response.data[3].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const championCenter = response.data[3].brackets.find(
          (x) => x.bracket_position === "center"
        );

        this.setState({
          championLeft: championLeft.games,
          championRight: championRight.games,
          championCenter: championCenter.games,
          challengeLeft: challengeLeft.games,
          challengeRight: challengeRight.games,
          challengeCenter: challengeCenter.games,
          mensTopLeftText: mensLeagueTopLeft.devision,
          mensTopRightText: mensLeagueTopRight.devision,
          mensBottomLeftText: mensLeagueBottomLeft.devision,
          mensBottomRightText: mensLeagueBottomRight.devision,
          mensCenterText: mensLeagueCenter.devision,
          mensLeagueTopLeft: mensLeagueTopLeft.games,
          mensLeagueBottomLeft: mensLeagueBottomLeft.games,
          mensLeagueTopRight: mensLeagueTopRight.games,
          mensLeagueBottomRight: mensLeagueBottomRight.games,
          mensLeagueCenter: mensLeagueCenter.games,
          survivorsLeft: survivorsLeft.games,
          survivorsRight: survivorsRight.games,
          survivorsCenter: survivorsCenter.games,
        });
      } else {
        console.log("Error while fetching data");
      }
    });
  };

  submitBracket = (stateKeys) => {
    stateKeys = [
      "mensLeagueTopLeft",
      "mensLeagueBottomLeft",
      "mensLeagueTopRight",
      "mensLeagueBottomRight",
      "mensLeagueCenter",
      "championLeft",
      "championRight",
      "championCenter",
      "challengeLeft",
      "challengeRight",
      "challengeCenter",
      "survivorsLeft",
      "survivorsRight",
      "survivorsCenter",
    ];
    let formDataArray = [];
    let error = 0;
    let errorData = [];
    let scoreError = 0;
    let scoreErrorData = [];
    stateKeys.map((key) => {
      let stateData = this.state[key];

      stateData.map((data) => {
        if (
          (parseInt(data.team1_score) === 0 &&
            parseInt(data.team2_score) === 0) ||
          (data.team2_score === null && data.team1_score === null)
        ) {
          if (data.team1.team_id !== null && data.team2.team_id !== null) {
            //error = 1;
            //errorData.push(data)
          }
        } else {
          console.log(data.team1_score + " " + data.team2_score);
          if (
            isNaN(data.team1_score) ||
            isNaN(data.team2_score) ||
            data.team1_score === null ||
            data.team2_score === null
          ) {
            scoreError = 1;
            scoreErrorData.push(data.game_id);
          } else {
            document
              .getElementById("match-" + data.game_id)
              .classList.remove("error");
            formDataArray.push({
              game_id: data.game_id,
              team1_score:
                data.team1_score || data.team1_score === 0
                  ? parseInt(data.team1_score)
                  : "",
              team2_score:
                data.team2_score || data.team2_score === 0
                  ? parseInt(data.team2_score)
                  : "",
            });
          }
        }
      });

      return null;
    });
    if (scoreError) {
      const firstBox = scoreErrorData[0];

      document.getElementById("match-" + firstBox).classList.add("error");
      this.setState({
        modalIsOpen: true,
        modalMsg: "Please add scores",
        btn1value: "Back",
      });
      return;
    }

    if (error) {
      console.log("errorData", errorData);
      const teamTitle =
        errorData[0].team1.name + " & " + errorData[0].team2.name;
      this.setState({
        modalIsOpen: true,
        modalMsg: "Please add scores for match between " + teamTitle,
        btn1value: "Back",
      });
    } else {
      let formData = new FormData();
      formData.append("game_data", JSON.stringify(formDataArray));
      this.setState({ loader: true });
      PostData("updateTeamScore", formData).then((response) => {
        this.setState({
          loader: false,
          modalIsOpen: true,
          btn1value: "Back",
          btn2value: "",
          modalMsg: "Your bracket scores has been saved",
        });
        console.log("response", response);
        this.getBrackets();
      });
    }
  };

  updateAllStates = (winningTeam, losingTeam, currentState, match) => {
    console.log("match", currentState, match);

    /*if(currentState === 'mensLeagueTopLeft' || currentState === 'mensLeagueBottomLeft')
        {
          let teamToUpdate = 'team1';
          if(match.position > 2 && match.position <= 4){ teamToUpdate = 'team2'; }
          if(match.position > 4 && match.position <= 6){ teamToUpdate = 'team1'; }
          if(match.position > 6 && match.position <= 8){ teamToUpdate = 'team2'; }
          if(match.round === 1)
          {
            const mensLeagueData = {...this.state[currentState]};
            console.log('mensLeagueData',mensLeagueData[6],teamToUpdate)
            if(mensLeagueData[6][teamToUpdate].team_id !== winningTeam.team_id){
              mensLeagueData[6][teamToUpdate] = {team_id:'',name:''}; 
            }
          }

          const mensLeagueCenterData = {...this.state['mensLeagueCenter']};
          let centerTeamId = 'team1'
          if(match.position > 4){ centerTeamId = 'team2' }


          if(mensLeagueCenterData[0][centerTeamId].team_id !== winningTeam.team_id){
            mensLeagueCenterData[0][centerTeamId] = {team_id:'',name:''}; 
          }
          
          if(mensLeagueCenterData[2]['team1'].team_id !== winningTeam.team_id){
            mensLeagueCenterData[2]['team1'] = {team_id:'',name:''}; 
          }
        }*/
  };

  setScore = (e, match, team, index, stateKey, event) => {
    if (isNaN(e.target.value)) {
      return;
    }

    if (e.target.value.length > 3) {
      return;
    }
    let currentStateData = this.state[stateKey];
    let currentTeam = "team1_score";
    if (team === "team2") {
      currentTeam = "team2_score";
    }
    currentStateData[index][currentTeam] =
      e.target.value == "0" ? "0" : parseInt(e.target.value);
    this.setState({ [stateKey]: currentStateData });
  };

  handleClick = (match, stateKey, team, losingTeam, index) => {
    let checkIt = this.updateAllStates(team, losingTeam, stateKey, match);

    if (checkIt === false) {
      return;
    }

    let currentStateData = this.state[stateKey][index];
    currentStateData["winner"] = team.team_id;
    currentStateData["selected"] = team.team_id;

    //this.setState({[stateKey]:currentStateData})
    //console.log('stateKey',stateKey,index,currentStateData)
    if (stateKey === "championCenter" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 3 && x.position === 1
      );
      const leagueIndexLosingTeam = leagueData.findIndex(
        (x) => x.round === 3 && x.position === 2
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
        leagueData[leagueIndexLosingTeam].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
        leagueData[leagueIndexLosingTeam].team2 = losingTeam;
      }

      this.setState({ [stateKey]: leagueData });
      return;
    }
    if (stateKey === "championCenter" && match.round === 3) {
      let leagueData = this.state[stateKey];
      this.setState({ [stateKey]: leagueData });
    }
    if (stateKey === "championLeft" || stateKey === "championRight") {
      let challengeKey = "championCenter";
      let leagueData = this.state[challengeKey];
      let position = 1;
      if (stateKey === "championRight") {
        position = 2;
      }

      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 2 && x.position === position
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
      }

      this.setState({ championCenter: leagueData });
      return;
    }

    if (stateKey === "challengeCenter" && match.round === 3) {
      let leagueData = this.state[stateKey];
      this.setState({ [stateKey]: leagueData });
    }
    if (stateKey === "challengeCenter" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 3 && x.position === 1
      );
      const leagueIndexLosingTeam = leagueData.findIndex(
        (x) => x.round === 3 && x.position === 2
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
        leagueData[leagueIndexLosingTeam].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
        leagueData[leagueIndexLosingTeam].team2 = losingTeam;
      }

      this.setState({ [stateKey]: leagueData });
      return;
    }

    if (stateKey === "challengeLeft" || stateKey === "challengeRight") {
      let challengeKey = "challengeCenter";
      let leagueData = this.state[challengeKey];
      let position = 1;
      if (stateKey === "challengeRight") {
        position = 2;
      }

      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 2 && x.position === position
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
      }

      this.setState({ challengeCenter: leagueData });
      return;
    }

    if (stateKey === "survivorsCenter" && match.round === 3) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 4 && x.position === 1
      );
      const leagueIndexLosingTeam = leagueData.findIndex(
        (x) => x.round === 4 && x.position === 2
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
        leagueData[leagueIndexLosingTeam].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
        leagueData[leagueIndexLosingTeam].team2 = losingTeam;
      }

      this.setState({ [stateKey]: leagueData });
      return null;
    }
    if (stateKey === "survivorsCenter" && match.round === 4) {
      let leagueData = this.state[stateKey];
      this.setState({ [stateKey]: leagueData });
    }
    if (stateKey === "survivorsLeft" || stateKey === "survivorsRight") {
      let round = 2;
      let position = 1;
      if (match.round === 2) {
        round = 3;
      }
      if (match.position > 2) {
        position = 2;
      }
      let leagueDataState = stateKey;
      if (round === 3) {
        leagueDataState = "survivorsCenter";
        if (stateKey === "survivorsRight") {
          position = 2;
        }
      }
      let leagueData = this.state[leagueDataState];
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === round && x.position === position
      );

      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
      }

      if (match.round === 1) {
        let chalengeStateKey = "challengeLeft";
        if (stateKey === "survivorsRight") {
          chalengeStateKey = "challengeRight";
        }
        let challengeCup = this.state[chalengeStateKey];

        let cposition = 1;
        if (match.position > 2) {
          cposition = 2;
        }
        const challengeIndex = leagueData.findIndex(
          (x) => x.position === cposition
        );

        if (match.position % 2 !== 0) {
          challengeCup[challengeIndex].team1 = losingTeam;
        }
        if (match.position % 2 === 0) {
          challengeCup[challengeIndex].team2 = losingTeam;
        }

        this.setState({ [chalengeStateKey]: challengeCup });
      }

      this.setState({ [leagueDataState]: leagueData });
      return;
    }

    if (match.round === 1) {
      let leagueData = this.state[stateKey];
      let position = 1;
      if (match.position > 2) {
        position = 2;
      }
      if (match.position > 4) {
        position = 3;
      }
      if (match.position > 6) {
        position = 4;
      }
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 2 && x.position === position
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
      }

      let survivorKey = 0;
      if (match.position > 2) {
        survivorKey = 1;
      }
      if (match.position > 4) {
        survivorKey = 2;
      }
      if (match.position > 6) {
        survivorKey = 3;
      }
      let survivorState = "survivorsLeft";
      if (
        stateKey === "mensLeagueTopRight" ||
        stateKey === "mensLeagueBottomRight"
      ) {
        survivorState = "survivorsRight";
      }
      let survivorsData = this.state[survivorState];
      if (match.position % 2 !== 0) {
        survivorsData[survivorKey].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        survivorsData[survivorKey].team2 = losingTeam;
      }

      this.setState({ [stateKey]: leagueData });
    }

    if (match.round === 2) {
      let championState = "championLeft";
      if (
        stateKey === "mensLeagueTopRight" ||
        stateKey === "mensLeagueBottomRight"
      ) {
        championState = "championRight";
      }
      let championData = this.state[championState];

      let championKey = 0;
      if (match.position > 2) {
        championKey = 1;
      }
      if (match.position % 2 !== 0) {
        championData[championKey].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        championData[championKey].team2 = losingTeam;
      }

      this.setState({ [championState]: championData });
    }

    if (stateKey === "mensLeagueTopLeft" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex((x) => x.round === 3);
      if (match.position === 1) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 2) {
        leagueData[leagueIndex].team2 = team;
      }
      this.setState({ mensLeagueTopLeft: leagueData });
    }
    if (stateKey === "mensLeagueBottomLeft" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex((x) => x.round === 3);
      if (match.position === 3) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 4) {
        leagueData[leagueIndex].team2 = team;
      }
      this.setState({ mensLeagueBottomLeft: leagueData });
    }

    if (stateKey === "mensLeagueTopRight" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex((x) => x.round === 3);
      if (match.position === 1) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 2) {
        leagueData[leagueIndex].team2 = team;
      }
      this.setState({ mensLeagueTopRight: leagueData });
    }

    if (stateKey === "mensLeagueBottomRight" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex((x) => x.round === 3);
      if (match.position === 3) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 4) {
        leagueData[leagueIndex].team2 = team;
      }
      this.setState({ mensLeagueBottomRight: leagueData });
    }

    if (
      (stateKey === "mensLeagueTopLeft" ||
        stateKey === "mensLeagueBottomLeft") &&
      match.round === 3
    ) {
      let leagueData = this.state.mensLeagueCenter;
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 4 && x.position === 1
      );
      if (match.position === 1) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 2) {
        leagueData[leagueIndex].team2 = team;
      }
      this.setState({ mensLeagueCenter: leagueData });
    }

    if (
      (stateKey === "mensLeagueTopRight" ||
        stateKey === "mensLeagueBottomRight") &&
      match.round === 3
    ) {
      let leagueData = this.state.mensLeagueCenter;
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 4 && x.position === 2
      );
      if (match.position === 1) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 2) {
        leagueData[leagueIndex].team2 = team;
      }
      this.setState({ mensLeagueCenter: leagueData });
    }

    if (stateKey === "mensLeagueCenter" && match.round === 4) {
      let leagueData = this.state.mensLeagueCenter;
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 5 && x.position === 1
      );
      if (match.position === 1) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 2) {
        leagueData[leagueIndex].team2 = team;
      }

      const LosingLeagueIndex = leagueData.findIndex(
        (x) => x.round === 5 && x.position === 2
      );
      if (match.position === 1) {
        leagueData[LosingLeagueIndex].team1 = losingTeam;
      }
      if (match.position === 2) {
        leagueData[LosingLeagueIndex].team2 = losingTeam;
      }

      this.setState({ mensLeagueCenter: leagueData });
    }
    if (stateKey === "mensLeagueCenter" && match.round === 5) {
      let leagueData = this.state.mensLeagueCenter;
      this.setState({ mensLeagueCenter: leagueData });
    }
  };

  setAddClass = () => {
    this.setState({
      addClass: true,
      addClass1: false,
      addClass2: false,
      tabNumber: 1,
    });
  };
  setAddClass1 = () => {
    this.setState({
      addClass1: true,
      addClass: false,
      addClass2: false,
      tabNumber: 2,
    });
  };
  setAddClass2 = () => {
    this.setState({
      addClass2: true,
      addClass1: false,
      addClass: false,
      tabNumber: 3,
    });
  };
  setAddClass3 = () => {
    this.setState({
      addClass3: false,
      addClass1: false,
      addClass: false,
      tabNumber: 3,
    });
  };

  arrowClass = () => {
    if (this.state.tabNumber == 3) {
      this.setAddClass1();
    } else if (this.state.tabNumber == 2) {
      this.setAddClass();
    } else if (this.state.tabNumber == 1) {
      this.setAddClass3();
    } else {
    }
  };

  closelogin = () => {
    this.setState({ importModal: false });
  };

  render() {
    return (
      <Aux>
        <Loader show={this.state.loader} />
        <Menu />
        <div className="Maincontent admin-container leader-board-wrap">
          <div className="container-fluid">
            <Row>
              <Col md="12">
                <div className="leaderord headerAdvt">
                  <Placeadd />
                </div>
              </Col>
            </Row>
            <div className="leader-board-toplinks">
              <Container>
                <Row>
                  <div className="col-12">
                    <ul>
                      <li>
                        <div className="premier-links"
                          onClick={() =>
                            this.props.navigate("/manage-mens-bracket")
                          }
                        >
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/men-icon.png"
                            className="premier-icon"
                          />{" "}
                          MEN’S TOURNAMENT
                        </div>
                        <div
                          className="premier-links"
                          onClick={() =>
                            this.props.navigate("/manage-womens-bracket")
                          }
                        >
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/women-icon.png"
                            className="premier-icon"
                          />{" "}
                          WOMEN’S TOURNAMENT
                        </div>
                        <div
                          className="premier-links active"
                          onClick={() =>
                            this.props.navigate("/my-groups")
                          }
                        >
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/men-icon.png"
                            className="premier-icon"
                          />{" "}
                          GROUPS
                        </div>
                      </li>
                    </ul>
                  </div>
                </Row>
              </Container>
            </div>
            <div className="invite-body-outer">
                <div className="rugby-bracket-wrap">
                  <div className="groups-wrap-outer">
                    
                    {/* Groups top and Table for groups */}
                    <GroupsTable/>

                  </div>
                </div>
            </div>
            <Placeaddsm />
            <Modal
              imgsrc={logo}
              toggle={() => this.setState({ modalIsOpen: false })}
              showModal={this.state.modalIsOpen}
              title="MEN’S PREMIER CUP"
              className="rugby-mens-bracket"
              bodytext={this.state.modalMsg}
              btn1value={this.state.btn1value}
              btn2value={this.state.btn2value}
              button1Click={() => this.setState({ modalIsOpen: false })}
              button2Click={this.forceUpdate}
            />
          </div>
        </div>
      </Aux>
    );
  }
}
export default withRouter(RugbyBracket);
