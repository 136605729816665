import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "./includes/Navbar";
import { withRouter } from "../components/withRouter";
import { Row, Col, Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeadd from "../components/Placeadd";
import Placeaddsm from "../components/Placeaddsm";
import { GetData, PostData } from "../utils/apiRequestHandler";
import BracketBox from "../components/brackets/BracketBox";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
const logo =  "https://ncrprodassets.s3.us-west-2.amazonaws.com/src_images/logo/logo.png";

class RugbyBracket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      modalIsOpen: false,
      type: "input",
      addClass: false,
      addClass1: false,
      addClass2: false,
      addClass3: false,
      tabNumber: 1,
      mensLeagueTopLeft: [],
      mensLeagueBottomLeft: [],
      mensLeagueTopRight: [],
      mensLeagueBottomRight: [],
      mensLeagueCenter: [],
      mensCenterText: "--",
      mensTopLeftText: "--",
      mensTopRightText: "--",
      mensBottomLeftText: "--",
      mensBottomRightText: "--",
      survivorsCenter: [],
      survivorsLeft: [],
      survivorsRight: [],
      challengeCenter: [],
      challengeLeft: [],
      challengeRight: [],
      championLeft: [],
      championRight: [],
      championCenter: [],
      showPremierScroll: true,
    };
    this._userBracketId = "";
    this.showHide = () => this.showHide.bind(this);
  }

  componentDidMount() {
    document.title = "NCR Rugby | Admin Men's Bracket";
    if (localStorage.tp !== "1") {
      this.props.navigate("/");
    } else {
      this.getBrackets();
    }
  }

  getBrackets = () => {
    this.setState({ loader: true });
    GetData("getGameLists?gender=male").then((response) => {
      //console.log('Response',response)
      this.setState({ loader: false });
      if (response.status === true) {
        this._userBracketId = response.data[0].user_bracket_id;
        const mensLeagueTopLeft = response.data[0].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const mensLeagueBottomLeft = response.data[0].brackets.find(
          (x) => x.bracket_position === "bottom_left"
        );
        const mensLeagueTopRight = response.data[0].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const mensLeagueBottomRight = response.data[0].brackets.find(
          (x) => x.bracket_position === "bottom_right"
        );
        const mensLeagueCenter = response.data[0].brackets.find(
          (x) => x.bracket_position === "center"
        );

        const survivorsLeft = response.data[1].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const survivorsRight = response.data[1].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const survivorsCenter = response.data[1].brackets[2];

        const challengeLeft = response.data[2].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const challengeRight = response.data[2].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const challengeCenter = response.data[2].brackets.find(
          (x) => x.bracket_position === "center"
        );

        const championLeft = response.data[3].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const championRight = response.data[3].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const championCenter = response.data[3].brackets.find(
          (x) => x.bracket_position === "center"
        );

        this.setState({
          championLeft: championLeft.games,
          championRight: championRight.games,
          championCenter: championCenter.games,
          challengeLeft: challengeLeft.games,
          challengeRight: challengeRight.games,
          challengeCenter: challengeCenter.games,
          mensTopLeftText: mensLeagueTopLeft.devision,
          mensTopRightText: mensLeagueTopRight.devision,
          mensBottomLeftText: mensLeagueBottomLeft.devision,
          mensBottomRightText: mensLeagueBottomRight.devision,
          mensCenterText: mensLeagueCenter.devision,
          mensLeagueTopLeft: mensLeagueTopLeft.games,
          mensLeagueBottomLeft: mensLeagueBottomLeft.games,
          mensLeagueTopRight: mensLeagueTopRight.games,
          mensLeagueBottomRight: mensLeagueBottomRight.games,
          mensLeagueCenter: mensLeagueCenter.games,
          survivorsLeft: survivorsLeft.games,
          survivorsRight: survivorsRight.games,
          survivorsCenter: survivorsCenter.games,
        });
      } else {
        console.log("Error while fetching data");
      }
    });
  };

  submitBracket = (stateKeys) => {
    stateKeys = [
      "mensLeagueTopLeft",
      "mensLeagueBottomLeft",
      "mensLeagueTopRight",
      "mensLeagueBottomRight",
      "mensLeagueCenter",
      "championLeft",
      "championRight",
      "championCenter",
      "challengeLeft",
      "challengeRight",
      "challengeCenter",
      "survivorsLeft",
      "survivorsRight",
      "survivorsCenter",
    ];
    let formDataArray = [];
    let error = 0;
    let errorData = [];
    let scoreError = 0;
    let scoreErrorData = [];
    
    stateKeys.map((key) => {
      let stateData = this.state[key];

      stateData.map((data) => {
        if (
          (parseInt(data.team1_score) === 0 &&
            parseInt(data.team2_score) === 0) ||
          (data.team2_score === null && data.team1_score === null)
        ) {
          if (data.team1.team_id !== null && data.team2.team_id !== null) {
            //error = 1;
            //errorData.push(data)
          }
        } else {
          console.log(data.team1_score + " " + data.team2_score);
          if (
            isNaN(data.team1_score) ||
            isNaN(data.team2_score) ||
            data.team1_score === null ||
            data.team2_score === null
          ) {
            scoreError = 1;
            scoreErrorData.push(data.game_id);
            // Still add the data to formDataArray even if there's an error
            // Use default values of 0 for missing scores
            formDataArray.push({
              game_id: data.game_id,
              team1_score: data.team1_score ? parseInt(data.team1_score) : 0,
              team2_score: data.team2_score ? parseInt(data.team2_score) : 0,
            });
          } else {
            document
              .getElementById("match-" + data.game_id)
              .classList.remove("error");
            formDataArray.push({
              game_id: data.game_id,
              team1_score:
                data.team1_score || data.team1_score === 0
                  ? parseInt(data.team1_score)
                  : "",
              team2_score:
                data.team2_score || data.team2_score === 0
                  ? parseInt(data.team2_score)
                  : "",
            });
          }
        }
      });

      return null;
    });
    
    // Display error warning but still proceed with submission
    if (scoreError) {
      const firstBox = scoreErrorData[0];
      document.getElementById("match-" + firstBox).classList.add("error");
      
      // You can uncomment this if you want to show a warning before submitting
      /*
      this.setState({
        modalIsOpen: true,
        modalMsg: "There are some score errors. Do you still want to submit?",
        btn1value: "Cancel",
        btn2value: "Submit Anyway",
        modalCallback: () => this.proceedWithSubmission(formDataArray)
      });
      return;
      */
    }

    // Always proceed with submission regardless of errors
    this.proceedWithSubmission(formDataArray);
  };

  // Helper method to handle the actual submission
  proceedWithSubmission = (formDataArray) => {
    let formData = new FormData();
    formData.append("game_data", JSON.stringify(formDataArray));
    this.setState({ loader: true });
    PostData("updateTeamScore", formData).then((response) => {
      this.setState({
        loader: false,
        modalIsOpen: true,
        btn1value: "Back",
        btn2value: "",
        modalMsg: "Your bracket scores has been saved",
      });
      console.log("response", response);
      this.getBrackets();
    });
  }

  updateAllStates = (winningTeam, losingTeam, currentState, match) => {
    console.log("match", currentState, match);

    /*if(currentState === 'mensLeagueTopLeft' || currentState === 'mensLeagueBottomLeft')
        {
          let teamToUpdate = 'team1';
          if(match.position > 2 && match.position <= 4){ teamToUpdate = 'team2'; }
          if(match.position > 4 && match.position <= 6){ teamToUpdate = 'team1'; }
          if(match.position > 6 && match.position <= 8){ teamToUpdate = 'team2'; }
          if(match.round === 1)
          {
            const mensLeagueData = {...this.state[currentState]};
            console.log('mensLeagueData',mensLeagueData[6],teamToUpdate)
            if(mensLeagueData[6][teamToUpdate].team_id !== winningTeam.team_id){
              mensLeagueData[6][teamToUpdate] = {team_id:'',name:''}; 
            }
          }

          const mensLeagueCenterData = {...this.state['mensLeagueCenter']};
          let centerTeamId = 'team1'
          if(match.position > 4){ centerTeamId = 'team2' }


          if(mensLeagueCenterData[0][centerTeamId].team_id !== winningTeam.team_id){
            mensLeagueCenterData[0][centerTeamId] = {team_id:'',name:''}; 
          }
          
          if(mensLeagueCenterData[2]['team1'].team_id !== winningTeam.team_id){
            mensLeagueCenterData[2]['team1'] = {team_id:'',name:''}; 
          }
        }*/
  };

  setScore = (e, match, team, index, stateKey, event) => {
    if (isNaN(e.target.value)) {
      return;
    }

    if (e.target.value.length > 3) {
      return;
    }
    let currentStateData = this.state[stateKey];
    let currentTeam = "team1_score";
    if (team === "team2") {
      currentTeam = "team2_score";
    }
    currentStateData[index][currentTeam] =
      e.target.value == "0" ? "0" : parseInt(e.target.value);
    this.setState({ [stateKey]: currentStateData });
  };

  handleClick = (match, stateKey, team, losingTeam, index) => {
    let checkIt = this.updateAllStates(team, losingTeam, stateKey, match);

    if (checkIt === false) {
      return;
    }

    let currentStateData = this.state[stateKey][index];
    currentStateData["winner"] = team.team_id;
    currentStateData["selected"] = team.team_id;

    //this.setState({[stateKey]:currentStateData})
    //console.log('stateKey',stateKey,index,currentStateData)
    if (stateKey === "championCenter" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 3 && x.position === 1
      );
      const leagueIndexLosingTeam = leagueData.findIndex(
        (x) => x.round === 3 && x.position === 2
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
        leagueData[leagueIndexLosingTeam].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
        leagueData[leagueIndexLosingTeam].team2 = losingTeam;
      }

      this.setState({ [stateKey]: leagueData });
      return;
    }
    if (stateKey === "championCenter" && match.round === 3) {
      let leagueData = this.state[stateKey];
      this.setState({ [stateKey]: leagueData });
    }
    if (stateKey === "championLeft" || stateKey === "championRight") {
      let challengeKey = "championCenter";
      let leagueData = this.state[challengeKey];
      let position = 1;
      if (stateKey === "championRight") {
        position = 2;
      }

      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 2 && x.position === position
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
      }

      this.setState({ championCenter: leagueData });
      return;
    }

    if (stateKey === "challengeCenter" && match.round === 3) {
      let leagueData = this.state[stateKey];
      this.setState({ [stateKey]: leagueData });
    }
    if (stateKey === "challengeCenter" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 3 && x.position === 1
      );
      const leagueIndexLosingTeam = leagueData.findIndex(
        (x) => x.round === 3 && x.position === 2
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
        leagueData[leagueIndexLosingTeam].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
        leagueData[leagueIndexLosingTeam].team2 = losingTeam;
      }

      this.setState({ [stateKey]: leagueData });
      return;
    }

    if (stateKey === "challengeLeft" || stateKey === "challengeRight") {
      let challengeKey = "challengeCenter";
      let leagueData = this.state[challengeKey];
      let position = 1;
      if (stateKey === "challengeRight") {
        position = 2;
      }

      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 2 && x.position === position
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
      }

      this.setState({ challengeCenter: leagueData });
      return;
    }

    if (stateKey === "survivorsCenter" && match.round === 3) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 4 && x.position === 1
      );
      const leagueIndexLosingTeam = leagueData.findIndex(
        (x) => x.round === 4 && x.position === 2
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
        leagueData[leagueIndexLosingTeam].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
        leagueData[leagueIndexLosingTeam].team2 = losingTeam;
      }

      this.setState({ [stateKey]: leagueData });
      return null;
    }
    if (stateKey === "survivorsCenter" && match.round === 4) {
      let leagueData = this.state[stateKey];
      this.setState({ [stateKey]: leagueData });
    }
    if (stateKey === "survivorsLeft" || stateKey === "survivorsRight") {
      let round = 2;
      let position = 1;
      if (match.round === 2) {
        round = 3;
      }
      if (match.position > 2) {
        position = 2;
      }
      let leagueDataState = stateKey;
      if (round === 3) {
        leagueDataState = "survivorsCenter";
        if (stateKey === "survivorsRight") {
          position = 2;
        }
      }
      let leagueData = this.state[leagueDataState];
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === round && x.position === position
      );

      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
      }

      if (match.round === 1) {
        let chalengeStateKey = "challengeLeft";
        if (stateKey === "survivorsRight") {
          chalengeStateKey = "challengeRight";
        }
        let challengeCup = this.state[chalengeStateKey];

        let cposition = 1;
        if (match.position > 2) {
          cposition = 2;
        }
        const challengeIndex = leagueData.findIndex(
          (x) => x.position === cposition
        );

        if (match.position % 2 !== 0) {
          challengeCup[challengeIndex].team1 = losingTeam;
        }
        if (match.position % 2 === 0) {
          challengeCup[challengeIndex].team2 = losingTeam;
        }

        this.setState({ [chalengeStateKey]: challengeCup });
      }

      this.setState({ [leagueDataState]: leagueData });
      return;
    }

    if (match.round === 1) {
      let leagueData = this.state[stateKey];
      let position = 1;
      if (match.position > 2) {
        position = 2;
      }
      if (match.position > 4) {
        position = 3;
      }
      if (match.position > 6) {
        position = 4;
      }
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 2 && x.position === position
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
      }

      let survivorKey = 0;
      if (match.position > 2) {
        survivorKey = 1;
      }
      if (match.position > 4) {
        survivorKey = 2;
      }
      if (match.position > 6) {
        survivorKey = 3;
      }
      let survivorState = "survivorsLeft";
      if (
        stateKey === "mensLeagueTopRight" ||
        stateKey === "mensLeagueBottomRight"
      ) {
        survivorState = "survivorsRight";
      }
      let survivorsData = this.state[survivorState];
      if (match.position % 2 !== 0) {
        survivorsData[survivorKey].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        survivorsData[survivorKey].team2 = losingTeam;
      }

      this.setState({ [stateKey]: leagueData });
    }

    if (match.round === 2) {
      let championState = "championLeft";
      if (
        stateKey === "mensLeagueTopRight" ||
        stateKey === "mensLeagueBottomRight"
      ) {
        championState = "championRight";
      }
      let championData = this.state[championState];

      let championKey = 0;
      if (match.position > 2) {
        championKey = 1;
      }
      if (match.position % 2 !== 0) {
        championData[championKey].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        championData[championKey].team2 = losingTeam;
      }

      this.setState({ [championState]: championData });
    }

    if (stateKey === "mensLeagueTopLeft" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex((x) => x.round === 3);
      if (match.position === 1) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 2) {
        leagueData[leagueIndex].team2 = team;
      }
      this.setState({ mensLeagueTopLeft: leagueData });
    }
    if (stateKey === "mensLeagueBottomLeft" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex((x) => x.round === 3);
      if (match.position === 3) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 4) {
        leagueData[leagueIndex].team2 = team;
      }
      this.setState({ mensLeagueBottomLeft: leagueData });
    }

    if (stateKey === "mensLeagueTopRight" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex((x) => x.round === 3);
      if (match.position === 1) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 2) {
        leagueData[leagueIndex].team2 = team;
      }
      this.setState({ mensLeagueTopRight: leagueData });
    }

    if (stateKey === "mensLeagueBottomRight" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex((x) => x.round === 3);
      if (match.position === 3) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 4) {
        leagueData[leagueIndex].team2 = team;
      }
      this.setState({ mensLeagueBottomRight: leagueData });
    }

    if (
      (stateKey === "mensLeagueTopLeft" ||
        stateKey === "mensLeagueBottomLeft") &&
      match.round === 3
    ) {
      let leagueData = this.state.mensLeagueCenter;
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 4 && x.position === 1
      );
      if (match.position === 1) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 2) {
        leagueData[leagueIndex].team2 = team;
      }
      this.setState({ mensLeagueCenter: leagueData });
    }

    if (
      (stateKey === "mensLeagueTopRight" ||
        stateKey === "mensLeagueBottomRight") &&
      match.round === 3
    ) {
      let leagueData = this.state.mensLeagueCenter;
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 4 && x.position === 2
      );
      if (match.position === 1) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 2) {
        leagueData[leagueIndex].team2 = team;
      }
      this.setState({ mensLeagueCenter: leagueData });
    }

    if (stateKey === "mensLeagueCenter" && match.round === 4) {
      let leagueData = this.state.mensLeagueCenter;
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 5 && x.position === 1
      );
      if (match.position === 1) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 2) {
        leagueData[leagueIndex].team2 = team;
      }

      const LosingLeagueIndex = leagueData.findIndex(
        (x) => x.round === 5 && x.position === 2
      );
      if (match.position === 1) {
        leagueData[LosingLeagueIndex].team1 = losingTeam;
      }
      if (match.position === 2) {
        leagueData[LosingLeagueIndex].team2 = losingTeam;
      }

      this.setState({ mensLeagueCenter: leagueData });
    }
    if (stateKey === "mensLeagueCenter" && match.round === 5) {
      let leagueData = this.state.mensLeagueCenter;
      this.setState({ mensLeagueCenter: leagueData });
    }
  };

  setAddClass = () => {
    this.setState({
      addClass: true,
      addClass1: false,
      addClass2: false,
      tabNumber: 1,
    });
  };
  setAddClass1 = () => {
    this.setState({
      addClass1: true,
      addClass: false,
      addClass2: false,
      tabNumber: 2,
    });
  };
  setAddClass2 = () => {
    this.setState({
      addClass2: true,
      addClass1: false,
      addClass: false,
      tabNumber: 3,
    });
  };
  setAddClass3 = () => {
    this.setState({
      addClass3: false,
      addClass1: false,
      addClass: false,
      tabNumber: 3,
    });
  };

  arrowClass = () => {
    if (this.state.tabNumber == 3) {
      this.setAddClass1();
    } else if (this.state.tabNumber == 2) {
      this.setAddClass();
    } else if (this.state.tabNumber == 1) {
      this.setAddClass3();
    } else {
    }
  };

  closelogin = () => {
    this.setState({ importModal: false });
  };

  render() {
    return (
      <Aux>
        <Loader show={this.state.loader} />
        <Menu />
        <div className="Maincontent admin-container leader-board-wrap">
          <div className="container-fluid">
            <Row>
              <Col md="12">
                <div className="leaderord headerAdvt">
                  <Placeadd />
                </div>
              </Col>
            </Row>
            <div className="leader-board-toplinks">
              <Container>
                <Row>
                  <div className="col-12">
                    <ul>
                      <li>
                        <div className="premier-links active">
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/men-icon.png"
                            className="premier-icon"
                          />{" "}
                          MEN’S TOURNAMENT
                        </div>
                        <div
                          className="premier-links"
                          onClick={() =>
                            this.props.navigate("/manage-womens-bracket")
                          }
                        >
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/women-icon.png"
                            className="premier-icon"
                          />{" "}
                          WOMEN’S TOURNAMENT
                        </div>
                        <div
                          className="premier-links"
                          onClick={() => this.props.navigate("/my-groups")}
                        >
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/men-icon.png"
                            className="premier-icon"
                          />{" "}
                          GROUPS
                        </div>
                      </li>
                    </ul>
                  </div>
                </Row>
              </Container>
            </div>
            <div className="invite-body-outer">
              <div className="rugby-bracket-wrap">
                <h1></h1>

                <div className="bracket-wrapper-inner">
                  <div
                    className={
                      this.state.showPremierScroll === true
                        ? "brackets hbreakers scrollableBracket"
                        : "brackets hbreakers"
                    }
                    id="premiorBracket"
                  >
                    <ul
                      className={`
                                    ${
                                      this.state.addClass
                                        ? "bracket-rouds-wrap secRd-left"
                                        : "bracket-rouds-wrap"
                                    }
                                    ${this.state.addClass1 ? "secRd-left1" : ""}
                                    ${this.state.addClass2 ? "secRd-left2" : ""}
                                
                                `}
                    >
                      <li
                        className={`
                                        ${
                                          this.state.addClass
                                            ? "round-tab arrow-left shift-arr1"
                                            : "round-tab arrow-left"
                                        }
                                        ${
                                          this.state.addClass1
                                            ? "shift-arr2"
                                            : ""
                                        }
                                        ${
                                          this.state.addClass2
                                            ? "shift-arr3"
                                            : ""
                                        }
                                    
                                    `}
                      >
                        <span
                          onClick={
                            this.state.showPremierScroll === true
                              ? ""
                              : this.arrowClass
                          }
                        ></span>
                      </li>
                      <li className="round-tab r_1">
                        <span className="r_name">1st ROUND</span>
                        <span className="r_date">April 26</span>
                      </li>
                      <li
                        className="round-tab r_2"
                        onClick={
                          this.state.showPremierScroll === true
                          ? () => {}
                          : this.setAddClass
                        }
                      >
                        <span className="r_name">2nd ROUND</span>
                        <span className="r_date">April 26</span>
                      </li>
                      <li
                        className="round-tab r_3"
                        onClick={
                          this.state.showPremierScroll === true
                          ? () => {}
                          : this.setAddClass1
                        }
                      >
                        <span className="r_name">QUARTERFINALS</span>
                        <span className="r_date">April 26</span>
                      </li>
                      <li
                        className="round-tab r_4"
                        onClick={
                          this.state.showPremierScroll === true
                          ? () => {}
                          : this.setAddClass2
                        }
                      >
                        <span className="r_name">SEMI FINALS</span>
                        <span className="r_date">April 27</span>
                      </li>
                      <li
                        className="round-tab championship"
                        onClick={
                          this.state.showPremierScroll === true
                          ? () => {}
                          : this.setAddClass2
                        }
                      >
                        <span className="r_name">CHAMPIONSHIP</span>
                        <span className="r_date">April 27</span>
                      </li>
                      <li className="round-tab r_4">
                        <span className="r_name">SEMI FINALS</span>
                        <span className="r_date">April 27</span>
                      </li>
                      <li className="round-tab r_3">
                        <span className="r_name">QUARTERFINALS</span>
                        <span className="r_date">April 26</span>
                      </li>
                      <li className="round-tab r_2">
                        <span className="r_name">2nd ROUND</span>
                        <span className="r_date">April 26</span>
                      </li>
                      <li className="round-tab r_1">
                        <span className="r_name">1st ROUND</span>
                        <span className="r_date">April 26</span>
                      </li>
                    </ul>

                    <div className="bracket-challenge-head"></div>
                    <div className="premier-cup-head">
                      MEN’S PREMIER
                      <br />
                      CUP BRACKET
                    </div>
                    <div
                      className={`
                                    ${
                                      this.state.addClass
                                        ? "pairs-wrap sec-pair"
                                        : "pairs-wrap"
                                    }
                                    ${this.state.addClass1 ? "sec-pair1" : ""}
                                    ${this.state.addClass2 ? "sec-pair2" : ""}
                                `}
                    >
                      {/* <div className="pairs-wrap"> */}
                      {/* first pair */}
                      <div className="mW mW_1">
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueTopLeft"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueTopLeft}
                          round={1}
                          position={[1, 2]}
                        />
                      </div>
                      {/* second pair */}
                      <div className="mW mW_2">
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueTopLeft"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueTopLeft}
                          round={1}
                          position={[3, 4]}
                        />
                      </div>

                      {/* third pair */}
                      <div className="mW mW_3">
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueBottomLeft"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueBottomLeft}
                          round={1}
                          position={[5, 6]}
                        />
                      </div>
                      {/* fourth pair */}
                      <div className="mW mW_4">
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueBottomLeft"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueBottomLeft}
                          round={1}
                          position={[7, 8]}
                        />
                      </div>
                      {/* 5th pair */}
                      <div className="mW mW_5">
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueTopRight"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueTopRight}
                          round={1}
                          position={[1, 2]}
                        />
                      </div>
                      {/* 6th pair */}
                      <div className="mW mW_6">
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueTopRight"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueTopRight}
                          round={1}
                          position={[3, 4]}
                        />
                      </div>
                      {/* 7th pair */}
                      <div className="mW mW_7">
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueBottomRight"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueBottomRight}
                          round={1}
                          position={[5, 6]}
                        />
                      </div>
                      {/* 8th pair */}
                      <div className="mW mW_8">
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueBottomRight"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueBottomRight}
                          round={1}
                          position={[7, 8]}
                        />
                      </div>
                      {/* 9th pair */}
                      <div
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_9 m9Sec"
                                        : "mW mW_9"
                                    }
                                    ${this.state.addClass1 ? "m9Sec" : ""}
                                    ${this.state.addClass2 ? "m9Sec" : ""}
                                `}
                      >
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueTopLeft"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueTopLeft}
                          type={2}
                          round={2}
                          position={[1, 2]}
                        />
                      </div>
                      {/* 10th pair */}
                      <div
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_10 m10Sec"
                                        : "mW mW_10"
                                    }
                                    ${this.state.addClass1 ? "m10Sec" : ""}
                                    ${this.state.addClass2 ? "m10Sec" : ""}
                                `}
                      >
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueBottomLeft"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueBottomLeft}
                          type={2}
                          round={2}
                          position={[3, 4]}
                        />
                      </div>

                      {/* 11th pair */}
                      <div
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_11 m11Sec"
                                        : "mW mW_11"
                                    }
                                    ${this.state.addClass1 ? "m11Sec" : ""}
                                    ${this.state.addClass2 ? "m11Sec" : ""}
                                `}
                      >
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueTopRight"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueTopRight}
                          type={2}
                          round={2}
                          position={[1, 2]}
                        />
                      </div>
                      {/* 12th pair */}
                      <div
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_12 m12Sec"
                                        : "mW mW_12"
                                    }
                                    ${this.state.addClass1 ? "m12Sec" : ""}
                                    ${this.state.addClass2 ? "m12Sec" : ""}
                                `}
                      >
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueBottomRight"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueBottomRight}
                          type={2}
                          round={2}
                          position={[3, 4]}
                        />
                      </div>
                      {/* 13th pair */}
                      <div
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_13 m13Sec"
                                        : "mW mW_13"
                                    }
                                    ${this.state.addClass1 ? "m13Sec1" : ""}
                                    ${this.state.addClass2 ? "m13Sec1" : ""}
                                `}
                      >
                        <div
                          className={`
                                        ${
                                          this.state.addClass
                                            ? "regions-left regTop"
                                            : "regions-left"
                                        }
                                        ${this.state.addClass1 ? "regTop1" : ""}
                                    `}
                        >
                          {this.state.mensTopLeftText}
                        </div>
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueTopLeft"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueTopLeft}
                          type={2}
                          round={3}
                          position={[1]}
                        />
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueBottomLeft"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueBottomLeft}
                          type={2}
                          round={3}
                          position={[2]}
                        />
                        <div
                          className={`
                                        ${
                                          this.state.addClass
                                            ? "regions-left-bottom regBot"
                                            : "regions-left-bottom"
                                        }
                                        ${this.state.addClass1 ? "regBot1" : ""}
                                    `}
                        >
                          {this.state.mensBottomLeftText}
                        </div>
                      </div>
                      {/* 14th pair */}
                      <div
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_14 m14Sec"
                                        : "mW mW_14"
                                    }
                                    ${this.state.addClass1 ? "m14Sec1" : ""}
                                    ${this.state.addClass2 ? "m14Sec1" : ""}
                                `}
                      >
                        {/* <div className="regions-right">WEST</div> */}
                        <div
                          className={`
                                        ${
                                          this.state.addClass
                                            ? "regions-right regTop"
                                            : "regions-right"
                                        }
                                        ${this.state.addClass1 ? "regTop1" : ""}
                                    `}
                        >
                          {this.state.mensTopRightText}
                        </div>
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueTopRight"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueTopRight}
                          type={2}
                          round={3}
                          position={[1]}
                        />
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueBottomRight"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueBottomRight}
                          type={2}
                          round={3}
                          position={[2]}
                        />
                        {/* <div className="regions-right-bottom">CENTRAL</div> */}
                        <div
                          className={`
                                        ${
                                          this.state.addClass
                                            ? "regions-right-bottom regBot"
                                            : "regions-right-bottom"
                                        }
                                        ${this.state.addClass1 ? "regBot1" : ""}
                                    `}
                        >
                          {this.state.mensBottomRightText}
                        </div>
                      </div>
                      {/* 15th pair */}
                      <div
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_15 m15Sec"
                                        : "mW mW_15"
                                    }
                                    ${this.state.addClass1 ? "m15Sec1" : ""}
                                    ${this.state.addClass2 ? "m15Sec2" : ""}
                                `}
                      >
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueCenter"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueCenter}
                          type={2}
                          round={4}
                          position={[1]}
                        />
                      </div>
                      {/* 16th pair */}
                      <div
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_16 m16Sec"
                                        : "mW mW_16"
                                    }
                                    ${this.state.addClass1 ? "m16Sec1" : ""}
                                    ${this.state.addClass2 ? "m16Sec2" : ""}
                                `}
                      >
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueCenter"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueCenter}
                          type={2}
                          round={4}
                          position={[2]}
                        />
                      </div>
                      {/* 17th pair */}
                      <div
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_17 m17Sec"
                                        : "mW mW_17"
                                    }
                                    ${this.state.addClass1 ? "m17Sec1" : ""}
                                    ${this.state.addClass2 ? "m17Sec2" : ""}
                                `}
                      >
                        <div className="nat-champion-text">
                          National Championship
                        </div>
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueCenter"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueCenter}
                          type={2}
                          round={5}
                          position={[1]}
                        />
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueCenter"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueCenter}
                          type={2}
                          round={5}
                          position={[2]}
                        />
                        <div
                          className="third-place-text"
                          style={{ textTransform: "none" }}
                        >
                          3rd Place
                        </div>
                      </div>
                    </div>
                    {/* pairs end */}
                    <div className="logo-brackets-bottom">
                      <img
                        src="/assets/images/logo-brackets.png"
                        alt="Bracket challenge"
                      />
                      <div
                        className="submit-bracket"
                        style={{ position: "unset", marginTop: "20px" }}
                      >
                        <input
                          type="button"
                          className="submit-style"
                          value="SAVE"
                          onClick={() =>
                            this.submitBracket([
                              "mensLeagueTopLeft",
                              "mensLeagueBottomLeft",
                              "mensLeagueTopRight",
                              "mensLeagueBottomRight",
                              "mensLeagueCenter",
                            ])
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {/* -------------------------champion cup bracket ------------------------------*/}
                  <div className="brackets champion-bracket-wrap">
                    <div className="champion-head">PLATE</div>
                    <div className="champion-bracket-outer">
                      <div className="champion-bracket-inner">
                        {/* left col pair 1 */}
                        <div className="mW mW_32">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="championLeft"
                            handleClick={this.handleClick}
                            data={this.state.championLeft}
                            type={2}
                            round={1}
                            position={[1, 2]}
                          />
                        </div>
                        {/* right col pair 1 */}
                        <div className="mW mW_33">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="championRight"
                            handleClick={this.handleClick}
                            data={this.state.championRight}
                            type={2}
                            round={1}
                            position={[1, 2]}
                          />
                        </div>
                        {/* left col 2 */}
                        <div className="mW mW_34">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="championCenter"
                            handleClick={this.handleClick}
                            data={this.state.championCenter}
                            type={2}
                            round={2}
                            position={[1]}
                          />
                        </div>
                        {/* lerightft col 2 */}
                        <div className="mW mW_35">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="championCenter"
                            handleClick={this.handleClick}
                            data={this.state.championCenter}
                            type={2}
                            round={2}
                            position={[2]}
                          />
                        </div>
                        {/* center col */}
                        <div className="mW mW_36">
                          <div className="champion-text">Championship</div>
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="championCenter"
                            handleClick={this.handleClick}
                            data={this.state.championCenter}
                            type={2}
                            round={3}
                            position={[1, 2]}
                          />
                          <div
                            className="third-place-text"
                            style={{ textTransform: "none" }}
                          >
                            3rd Place
                          </div>
                        </div>
                      </div>
                      <div
                        className="bracket-bottom-text challenge-bottom-text blue-bracket-bottom-text"
                        style={{ left: "0px" }}
                      >
                        2nd Round Losers
                        <br />
                         of Premier Cup
                      </div>
                      <div
                        className="submit-bracket"
                        style={{ position: "unset", marginTop: "20px" }}
                      >
                        <input
                          type="button"
                          className="submit-style"
                          value="SAVE"
                          onClick={() =>
                            this.submitBracket([
                              "championLeft",
                              "championRight",
                              "championCenter",
                            ])
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {/* -------------------------survivor cup bracket ------------------------------*/}
                  <div className="brackets survivor-bracket-wrap">
                    <div className="survivor-head"> BOWL</div>
                    <div className="survivor-bracket-outer">
                      <div className="survivor-bracket-inner">
                        {/* left col pair 1 */}
                        <div className="mW mW_18">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="survivorsLeft"
                            handleClick={this.handleClick}
                            data={this.state.survivorsLeft}
                            type={2}
                            round={1}
                            position={[1, 2]}
                          />
                        </div>
                        {/* left col pair 2 */}
                        <div className="mW mW_19">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="survivorsLeft"
                            handleClick={this.handleClick}
                            data={this.state.survivorsLeft}
                            type={2}
                            round={1}
                            position={[3, 4]}
                          />
                        </div>
                        {/* right col pair 1 */}
                        <div className="mW mW_20">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="survivorsRight"
                            handleClick={this.handleClick}
                            data={this.state.survivorsRight}
                            type={2}
                            round={1}
                            position={[1, 2]}
                          />
                        </div>
                        {/* right col pair 2 */}
                        <div className="mW mW_21">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="survivorsRight"
                            handleClick={this.handleClick}
                            data={this.state.survivorsRight}
                            type={2}
                            round={1}
                            position={[3, 4]}
                          />
                        </div>
                        {/* left col 2 */}
                        <div className="mW mW_22">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="survivorsLeft"
                            handleClick={this.handleClick}
                            data={this.state.survivorsLeft}
                            type={2}
                            round={2}
                            position={[1, 2]}
                          />
                        </div>
                        {/* right col 2 */}
                        <div className="mW mW_23">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="survivorsRight"
                            handleClick={this.handleClick}
                            data={this.state.survivorsRight}
                            type={2}
                            round={2}
                            position={[1, 2]}
                          />
                        </div>
                        {/* left col 3 */}
                        <div className="mW mW_24">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="survivorsCenter"
                            handleClick={this.handleClick}
                            data={this.state.survivorsCenter}
                            type={2}
                            round={3}
                            position={[1]}
                          />
                        </div>
                        {/* right col 3 */}
                        <div className="mW mW_25">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="survivorsCenter"
                            handleClick={this.handleClick}
                            data={this.state.survivorsCenter}
                            type={2}
                            round={3}
                            position={[2]}
                          />
                        </div>
                        {/* center pair */}
                        <div className="mW mW_26">
                          <div className="champion-text">Championship</div>
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="survivorsCenter"
                            handleClick={this.handleClick}
                            data={this.state.survivorsCenter}
                            type={2}
                            round={4}
                            position={[1, 2]}
                          />
                          <div
                            className="third-place-text"
                            style={{ textTransform: "none" }}
                          >
                            3rd Place
                          </div>
                        </div>
                      </div>
                      <div className="bracket-bottom-text blue-bracket-bottom-text">
                        1st Round Losers
                        <br />
                         From Premier Cup
                      </div>
                      <div
                        className="submit-bracket"
                        style={{ position: "unset", marginTop: "20px" }}
                      >
                        <input
                          type="button"
                          className="submit-style"
                          value="SAVE"
                          onClick={() =>
                            this.submitBracket([
                              "survivorsLeft",
                              "survivorsRight",
                              "survivorsCenter",
                            ])
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {/* -------------------------challenger cup bracket ------------------------------*/}
                  <div className="brackets challenge-bracket-wrap">
                    <div className="challenge-head">SHIELD</div>
                    <div className="challenge-bracket-outer">
                      <div className="challenge-bracket-inner">
                        {/* left col pair 1 */}
                        <div className="mW mW_27">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="challengeLeft"
                            handleClick={this.handleClick}
                            data={this.state.challengeLeft}
                            type={2}
                            round={1}
                            position={[1, 2]}
                          />
                        </div>
                        {/* right col pair 1 */}
                        <div className="mW mW_28">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="challengeRight"
                            handleClick={this.handleClick}
                            data={this.state.challengeRight}
                            type={2}
                            round={1}
                            position={[1, 2]}
                          />
                        </div>
                        {/* left col 2 */}
                        <div className="mW mW_29">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="challengeCenter"
                            handleClick={this.handleClick}
                            data={this.state.challengeCenter}
                            type={2}
                            round={2}
                            position={[1]}
                          />
                        </div>
                        {/* lerightft col 2 */}
                        <div className="mW mW_30">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="challengeCenter"
                            handleClick={this.handleClick}
                            data={this.state.challengeCenter}
                            type={2}
                            round={2}
                            position={[2]}
                          />
                        </div>
                        {/* center col */}
                        <div className="mW mW_31">
                          <div className="champion-text">Championship</div>
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="challengeCenter"
                            handleClick={this.handleClick}
                            data={this.state.challengeCenter}
                            type={2}
                            round={3}
                            position={[1, 2]}
                          />
                          <div
                            className="third-place-text"
                            style={{ textTransform: "none" }}
                          >
                            3rd Place
                          </div>
                        </div>
                      </div>
                      <div
                        className="bracket-bottom-text challenge-bottom-text blue-bracket-bottom-text"
                        style={{ left: "0px" }}
                      >
                        1st Round Losers
                        <br />
                         of Shield Bracket
                      </div>
                      <div
                        className="submit-bracket"
                        style={{ position: "unset", marginTop: "20px" }}
                      >
                        <input
                          type="button"
                          className="submit-style"
                          value="SAVE"
                          onClick={() =>
                            this.submitBracket([
                              "challengeLeft",
                              "challengeRight",
                              "challengeCenter",
                            ])
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Placeaddsm />
            <Modal
              imgsrc={logo}
              toggle={() => this.setState({ modalIsOpen: false })}
              showModal={this.state.modalIsOpen}
              title="MEN’S PREMIER CUP"
              className="rugby-mens-bracket"
              bodytext={this.state.modalMsg}
              btn1value={this.state.btn1value}
              btn2value={this.state.btn2value}
              button1Click={() => this.setState({ modalIsOpen: false })}
              button2Click={this.forceUpdate}
            />
          </div>
        </div>
      </Aux>
    );
  }
}
export default withRouter(RugbyBracket);
